import { AppBar, Paper, Tab, Tabs } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ExposureIcon from "@material-ui/icons/Exposure";
import React, { useState } from "react";
import { useAuth } from "../../../context/auth";
import Attendence from "./attendence/Attendence";
import { Fee } from "./fee/Fee";
import useStyles from "./ReportsStyle";

const TAB_MEMBER_ATTENDENCE = 0;
const TAB_TEAM_FEE = 1;

export default function Reports() {
  const { user } = useAuth();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(TAB_MEMBER_ATTENDENCE);
  const handleChange = (event, newValue) => setSelectedTab(newValue);

  return (
    <div>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="icon tabs example">
            <Tab label="Prisutnost članova" icon={<ExposureIcon />} />
            {user?.club?.isFinanceActive && <Tab label="Članarine" icon={<AccountBalanceIcon />} />}
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_MEMBER_ATTENDENCE && <Attendence />}
      {selectedTab === TAB_TEAM_FEE && <Fee />}
    </div>
  );
}
