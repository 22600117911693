import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { ConfirmProvider } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { UserService } from "../service/UserService";
import { AuthContext } from "./../context/auth";
import { handleErrorMessage } from "./Utils";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#5DAEBF",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#ffd0db",
    },
  },
});

const LOCAL_STORAGE_DATA = `${process.env.NODE_ENV}-token-evico`;

export default function Store({ children }) {
  const tawkMessengerRef = useRef();

  const [authData, setAuthData] = useState(localStorage.getItem(LOCAL_STORAGE_DATA) || "");
  const { enqueueSnackbar } = useSnackbar();

  const { axiosGetUser } = UserService();

  const showMessage = (text, type) => {
    enqueueSnackbar(text, {
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  useEffect(() => {
    const loggedInUserData = authData ? JSON.parse(authData) : null;
    if (loggedInUserData) {
      axiosGetUser(loggedInUserData.jwt)
        .then((res) => {
          const data = {
            jwt: loggedInUserData.jwt,
            user: res.data,
          };
          updateAuthData(data);
        })
        .catch((error) => {
          handleErrorMessage(showMessage, error);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAuthData = (data) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(LOCAL_STORAGE_DATA, jsonData);
    setAuthData(jsonData);
    // tawkMessengerRef.current.showWidget();
  };

  const deleteAuthData = () => {
    setAuthData("");
    localStorage.removeItem(LOCAL_STORAGE_DATA);
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <AuthContext.Provider
          value={{
            token: authData && authData !== "" ? JSON.parse(authData).jwt : undefined,
            user: authData && authData !== "" ? JSON.parse(authData).user : undefined,
            showMessage,
            updateAuthData,
            deleteAuthData,
            tawkMessengerRef,
          }}
        >
          {children}
        </AuthContext.Provider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
