import React from "react";
import MUIEditor from "react-mui-draft-wysiwyg";
import RichTextService from "./RichTextService";

export default function RichText({ muiEditorRef, placeholder, readOnly, controls, visible }) {
  const { editorState, onChange } = RichTextService({ muiEditorRef });

  return (
    <MUIEditor
      config={{
        draftEditor: {
          placeholder: placeholder,
          readOnly: readOnly,
        },
        toolbar: {
          controls: controls,
          visible: visible,
        },
      }}
      editorState={editorState}
      onChange={onChange}
    />
  );
}
