import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../../../context/auth";
import { getString } from "../../../../../lang/Local";
import Endpoints from "../../../../../utils/Endpoints";
import useStyles from "./RoleInfoStyle";

export default function RoleInfo() {
  const { token } = useAuth();

  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [role, setRole] = useState({
    name: "",
    nameShort: "",
    code: "",
    orderNumber: "",
    note: "",
  });
  useEffect(() => {
    if (id) {
      axios
        .get(Endpoints.ROLE + "/" + id, headers(token))
        .then((role) => {
          const roleOld = {};
          roleOld.id = role.data.id;
          roleOld.name = role.data.name;
          roleOld.nameShort = role.data.nameShort;
          roleOld.code = role.data.code;
          roleOld.orderNumber = role.data.orderNumber;
          roleOld.note = role.data.note;
          setRole(roleOld);
        })
        .catch((e) => {});
    }
  }, [id]);

  const save = (e) => {
    axios
      .post(Endpoints.ROLE, role, headers(token))
      .then((result) => {
        history.push("/roles");
      })
      .catch((e) => {});
  };

  const handleChange = (e) => {
    setRole({ ...role, [e.target.name]: e.target.value });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="name"
              name="name"
              label="Naziv"
              value={role.name}
              onChange={handleChange}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="nameShort"
              name="nameShort"
              label="Kratak naziv"
              value={role.nameShort}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="code"
              name="code"
              label="Šifra"
              type="number"
              value={role.code}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              id="orderNumber"
              name="orderNumber"
              label="Red broj"
              type="number"
              value={role.orderNumber}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              variant="outlined"
              margin="normal"
              id="note"
              name="note"
              label="Napomena"
              value={role.note}
              onChange={handleChange}
              fullWidth
              multiline
              rows={10}
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button variant="contained" onClick={(e) => history.push("/roles")} className={classes.button}>
            {getString("common.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={save} className={classes.button}>
            {getString("common.save")}
          </Button>
        </div>
      </Paper>
    </React.Fragment>
  );
}
