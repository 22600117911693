import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";
import MessageType from "../../../utils/MessageType";

export default function Locations() {
  const { token, showMessage } = useAuth();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.LOCATION, headers(token))
      .then((result) => {
        setLocations(result.data);
        setLoading(false);
      })
      .catch((e) => showMessage("Greška dohvatanja podataka", MessageType.ERROR));
  };

  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    { title: "Naziv", field: "name" },
    { title: "Adresa", field: "addressNumber" },
    { title: "Grad", field: "city" },
    { title: "Napomena", field: "note" },
  ];

  return (
    <ReactTable
      columns={columns}
      data={locations}
      loading={loading}
      reload={fetchData}
      opt={{ title: "Lokacije", item: "location", filter: false }}
    />
  );
}
