import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gridRow: {
    flexGrow: 1,
    padding: theme.spacing(1),
    textAlign: "center",
  },
  multiselect: {
    textAlign: "left",
    zIndex: "2",
    position: "relative",
  },
  calendar: {
    height: "500pt",
    zIndex: "1",
    position: "relative",
  },
}));

export default useStyles;
