import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper_global: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  paper: {
    width: 400,
    height: 600,
    overflow: "auto",
  },
  paper_title: {
    textAlign: "center",
    fontSize: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));
export default useStyles;
