import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import useStyles from "./ProfileStyle";
// import {} from "./index";

export default function Profile() {
  const { token, showMessage } = useAuth();

  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [user, setUser] = useState({
    name: "",
    surname: "",
    username: "",
    password: "",
    showPassword: false,
    gender: "",
    email: "",
    bornDate: "",
    phone: "",
    address: "",
    code: "",
  });

  useEffect(() => {
    axios
      .get(Endpoints.USER_PROFILE, headers(token))
      .then((response) => {
        setUser(response.data);
      })
      .catch((e) => {
        showMessage("Greška prilikom učitavanja " + e, MessageType.ERROR);
        console.log(e);
      });
  }, [id, token, showMessage]);

  const save = (e) => {
    axios
      .post(Endpoints.USER, user, headers(token))
      .then((result) => {
        showMessage("Uspešno sniljeni podaci.", MessageType.SUCCESS);

        history.push("/");
      })
      .catch((e) => {
        showMessage("Greška prilikom snimanja " + e, MessageType.ERROR);
        console.log(e);
      });
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Profil korisnika
          </Typography>
          <ValidatorForm>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" id="firstName" name="firstName" label="Ime" value={user.firstName ? user.firstName : ""} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" id="lastName" name="surname" label="Prezime" value={user.lastName ? user.lastName : ""} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="username"
                  name="username"
                  label="Korisničko ime"
                  value={user.username ? user.username : ""}
                  onChange={handleChange}
                  fullWidth
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="addressNumber"
                  name="addressNumber"
                  label="Adresa"
                  value={user.addressNumber ? user.addressNumber : ""}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  id="email"
                  name="email"
                  label="Email"
                  onChange={handleChange}
                  value={user.email ? user.email : ""}
                  validators={["isEmail"]}
                  required
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="birthDate"
                  name="birthDate"
                  label="Datum rođenja"
                  type="date"
                  value={user.birthDate}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="contactPhone"
                  name="contactPhone"
                  label="Broj telefona"
                  value={user.contactPhone ? user.contactPhone : ""}
                  onChange={handleChange}
                  fullWidth
                />
                {/* <MuiPhoneNumber
                  variant="outlined"
                  margin="normal"
                  id="contactPhone"
                  name="contactPhone"
                  label="Broj telefona"
                  value={user.contactPhone}
                  defaultCountry={"rs"}
                  onChange={(value) => {
                    setUser({
                      ...user,
                      phone: value,
                    });
                  }}
                  fullWidth
                /> */}
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button type="submit" variant="contained" color="primary" onClick={save} className={classes.button}>
                Snimi
              </Button>
            </div>
          </ValidatorForm>
        </Paper>
      </main>
    </React.Fragment>
  );
}
