import CategoryIcon from "@material-ui/icons/Category";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DescriptionIcon from "@material-ui/icons/Description";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import EventIcon from "@material-ui/icons/Event";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import GroupIcon from "@material-ui/icons/Group";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { MenuItem, Sidebar } from "react-pro-sidebar";
import { useHistory } from "react-router-dom";
import { MenuCustom } from "./styled";
import useSidemenu from "./useSidemenu";

export default function Sidemenu() {
  const { isFinanceActive } = useSidemenu();
  const history = useHistory();
  return (
    <Sidebar backgroundColor="#5DAEBF">
      <MenuCustom
        style={{ paddingTop: "16px" }}
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                "&:hover": {
                  backgroundColor: "#135462 !important",
                  color: "white !important",
                  borderRadius: "4px !important",
                  fontWeight: "bold !important",
                },
              };
            }
          },
        }}
      >
        <MenuItem style={{ color: "white" }} icon={<EventIcon />} onClick={() => history.push("/events")}>
          Kalendar
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<PersonIcon />} onClick={() => history.push("/members")}>
          Članovi
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<GroupIcon />} onClick={() => history.push("/teams")}>
          Timovi
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<SupervisorAccountIcon />} onClick={() => history.push("/users")}>
          Treneri
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<LocationOnIcon />} onClick={() => history.push("/locations")}>
          Lokacije
        </MenuItem>
        {isFinanceActive && (
          <MenuItem style={{ color: "white" }} icon={<CreditCardIcon />} onClick={() => history.push("/finance")}>
            Finansije
          </MenuItem>
        )}
        <MenuItem style={{ color: "white" }} icon={<CategoryIcon />} onClick={() => history.push("/exercisesType")}>
          Tip vežbe
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<FitnessCenterIcon />} onClick={() => history.push("/exercises")}>
          Vežbe
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<DescriptionIcon />} onClick={() => history.push("/legalDocuments")}>
          Pravni dokumenti
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<EqualizerIcon />} onClick={() => history.push("/reports")}>
          Izveštaji
        </MenuItem>
        <MenuItem style={{ color: "white" }} icon={<MessageIcon />} onClick={() => history.push("/posts")}>
          Obaveštenja
        </MenuItem>
      </MenuCustom>
    </Sidebar>
  );
}
