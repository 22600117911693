import PageviewIcon from "@material-ui/icons/Pageview";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function LegalDocuments() {
  const { token, showMessage, handleErrorMessage } = useAuth();
  const history = useHistory();
  const [legalDocuments, setLegalDocuments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setLoading(true);
    axios
      .get(Endpoints.LEGAL_DOCUMENTS, headers(token))
      .then((res) => setLegalDocuments(res.data))
      .catch((e) => handleErrorMessage(showMessage, e))
      .finally(() => setLoading(false));
  };

  const columns = [{ title: "Naziv", field: "name" }];

  return (
    <ReactTable
      columns={columns}
      data={legalDocuments}
      loading={loading}
      reload={fetchData}
      opt={{
        title: "Pravni dokumenti",
        item: "legalDocument",
        filter: false,
        path: Endpoints.LEGAL_DOCUMENTS,

        actions: [
          {
            icon: () => <PageviewIcon />,
            tooltip: "Pregled",
            onClick: (event, rowData) => window.open(`${Endpoints.FILES}/pdf/${rowData?.file?.id}`, "_blank").focus(),
          },
        ],
      }}
    />
  );
}
