import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import { handleErrorMessage, monthsInSerbian } from "../../../../utils/Utils";
import useEffectOnce from "../../../system/useEffectOnce";

export const Fee = () => {
  const { token, showMessage } = useAuth();

  const [teams, setTeams] = useState([]);

  const [searchParam, setSearchParam] = useState({});
  const [feeData, setFeeData] = useState();

  useEffectOnce(() => {
    axios
      .get(Endpoints.TEAM, headers(token))
      .then((response) => setTeams(response.data))
      .catch((e) => handleErrorMessage(showMessage, e));
  }, []);

  useEffect(() => {
    const { team, month, year } = searchParam ?? {};
    if (team && month && year) {
      axios
        .get(
          `${Endpoints.FEE}/view/team/${team.id}`,
          headers(token, {
            month: month.id,
            year: year.name,
          })
        )
        .then((response) => setFeeData(response.data))
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  }, [searchParam]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} spacing={3}>
        <Autocomplete
          options={teams}
          fullWidth
          getOptionLabel={(option) => option.name}
          value={searchParam?.team}
          onChange={(event, value) =>
            setSearchParam({
              ...searchParam,
              team: value,
            })
          }
          renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="policy" name="policy" label="Tim" fullWidth />}
        />
      </Grid>
      <Grid item xs={3} spacing={3}>
        <Autocomplete
          options={monthsInSerbian}
          fullWidth
          getOptionLabel={(option) => option.name}
          value={searchParam?.month}
          onChange={(event, value) =>
            setSearchParam({
              ...searchParam,
              month: value,
            })
          }
          renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="month" name="month" label="Mesec" fullWidth />}
        />
      </Grid>
      <Grid item xs={3} spacing={3}>
        <Autocomplete
          options={[
            { id: 1, name: "2024" },
            { id: 2, name: "2025" },
          ]}
          fullWidth
          getOptionLabel={(option) => option.name}
          value={searchParam?.year}
          onChange={(event, value) =>
            setSearchParam({
              ...searchParam,
              year: value,
            })
          }
          renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="year" name="year" label="Godina" fullWidth />}
        />
      </Grid>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            {feeData ? (
              <TableRow>
                <TableCell>Ime i prezime</TableCell>
                <TableCell>Iznos</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center">Izaberi grupu, mesec i godinu za prikaz članarina</TableCell>
              </TableRow>
            )}
          </TableHead>
          {feeData && (
            <TableBody>
              {searchParam?.team?.memberTeamList?.map(({ member }) => {
                const fee = feeData?.fees?.find((fee) => fee.member.id === member.id);
                return (
                  <TableRow key={member.id}>
                    <TableCell align="left">{member?.fullName}</TableCell>
                    <TableCell align="left">{fee?.amount}</TableCell>
                    <TableCell align="left">{moment(fee?.feeDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                  </TableRow>
                );
              })}
              {/* {eventData &&
              rows(eventData).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row?.fullName}</TableCell>
                      <TableCell key={columnIndex} align="center">
                        {column[index] ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
                      </TableCell>
                    ))}
                  <TableCell align="center">{eventData && showPresenceRelation(eventData, index)}</TableCell>
                  <TableCell align="center">{eventData && showPresencePercent(eventData, index)}</TableCell>
                </TableRow>
              ))} */}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );
};
