import MomentUtils from "@date-io/moment";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import "moment/locale/sr";
import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import MultiSelect from "react-multi-select-component";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { EventStatus } from "../../../utils/enum/EnumEventStatus";
import MessageType from "../../../utils/MessageType";
import { parseOption } from "../../../utils/ParseHelper";
import { dateTimeView, DATE_FORMAT3, handleErrorMessage } from "../../../utils/Utils";
import EventViewDialog from "../../system/dialogs/event-view/EventViewDialog";
import useEffectOnce from "../../system/useEffectOnce";
import useStyles from "./EventStyle";

moment().locale("sr");
const localizer = momentLocalizer(moment);

const allEventType = [
  {
    id: "TRAINING",
    name: "Trening",
  },
  {
    id: "GAME",
    name: "Utakmica",
  },
];

export default function Events() {
  const { token, showMessage } = useAuth();
  const confirm = useConfirm();
  const classes = useStyles();
  const parseEvent = (obj) => {
    return {
      id: obj ? obj.id : null,
      startTime: obj ? obj.start : new Date(),
      note: obj ? obj.note : "",
      team: obj ? parseOption(obj.team) : parseOption(),
      location: obj ? parseOption(obj.location) : parseOption(),
      eventType: obj ? parseOption(obj.eventType) : parseOption(),
    };
  };
  const [eventObj, setEventObj] = useState(parseEvent);
  const [user, setUser] = useState({});
  const [events, setEvents] = useState([]);
  const staticData = useRef([]);
  const [filter, setFilter] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [newDate, setNewDate] = useState(new Date());
  const myRef = useRef();
  const [openEditNew, setOpenEditNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const parseEvents = (allEvents) => {
    return allEvents.map((ev) => {
      const title = `${dateTimeView(ev.startTime).format("HH:mm")} ${ev.team?.name ?? ""} ${ev.eventType === "GAME" ? " - " + ev.otherTeam?.name : ""}`;
      return {
        id: ev.id,
        title: title,
        start: new Date(dateTimeView(ev.startTime)),
        end: new Date(dateTimeView(ev.startTime)),
        desc: title,
        note: ev.note,
        team: ev.team,
        otherTeam: ev.otherTeam,
        location: ev.location,
        eventType: ev.eventType,
        eventStatus: ev.eventStatus,
      };
    });
  };

  const fetchFilterData = () => {
    axios
      .get(Endpoints.EVENT + "/filterData", headers(token))
      .then((result) => {
        const { logUser, allUsers, allLocations, allTeams } = result.data;
        setUser(logUser);
        const parseUsers = allUsers.map((u) => {
          return { label: u.fullName, value: u.id, id: u.id, title: u.fullName };
        });
        const parseLocations = allLocations.map((l) => {
          return { label: l.name, value: l.id, id: l.id, title: l.name };
        });
        const parseTeams = allTeams.map((t) => {
          return { label: t.name, value: t.id, id: t.id, title: t.name };
        });
        const parseEventType = allEventType?.map((t) => {
          return { label: t.name, value: t.id, id: t.id, title: t.name };
        });
        setFilter([parseUsers, parseLocations, parseTeams, parseEventType]);
        setAllOptions([parseUsers, parseLocations, parseTeams, parseEventType]);
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const fetchData = () => {
    axios
      .get(Endpoints.EVENT + "/byDate/" + moment(newDate).format(DATE_FORMAT3), headers(token))
      .then((result) => {
        const allEvents = parseEvents(result.data);
        setEvents(allEvents);
        staticData.current = allEvents;
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  useEffectOnce(() => {
    fetchFilterData();
  });

  const editNewEvent = (item) => {
    setEventObj(item ? item : parseEvent(item));
    setOpenEditNew(true);
  };

  const saveEvent = () => {
    const newData = { ...eventObj };
    newData.subsititutionUser = user.id;
    newData.eventID = eventObj.id;
    newData.startTime = newData.startTime ? moment(newData.startTime).format(DATE_FORMAT3) : null;
    newData.teamID = eventObj.team.id;
    newData.locationID = eventObj.location.id;
    newData.eventType = eventObj.eventType.id;
    if (eventObj.eventType?.id === "GAME") {
      newData.otherTeamID = eventObj.otherTeam.id;
    }
    axios
      .post(Endpoints.EVENT, newData, headers(token))
      .then((response) => {
        fetchData();
        showMessage("Uspešno snimljeno", MessageType.SUCCESS);
        setOpenEditNew(false);
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  useEffect(() => {
    let allEvents = staticData.current;
    filter.forEach((obj, i) => {
      const selected = obj.map((obj) => obj.value);
      switch (i) {
        case 0:
          allEvents = allEvents.filter((data) => (data.team && selected.includes(data.team.headCoach.id)) || (data.otherTeam && selected.includes(data.otherTeam.headCoach.id)));
          break;
        case 1:
          allEvents = allEvents.filter((data) => data.location && selected.includes(data.location.id));
          break;
        case 2:
          allEvents = allEvents.filter((data) => (data.team && selected.includes(data.team.id)) || (data.otherTeam && selected.includes(data.otherTeam.id)));
          break;
        case 3:
          allEvents = allEvents.filter((data) => data.eventType && selected.includes(data.eventType));
          break;
        default:
          console.log("Ne treba da se desi");
          break;
      }
      setEvents(allEvents);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    fetchData();
  }, [newDate]);

  const eventDelete = () => {
    axios
      .delete(Endpoints.EVENT + "/" + eventObj.id, headers(token))
      .then((response) => {
        showMessage("Uspešno obrisano", MessageType.SUCCESS);
        setEventObj(undefined);
        setOpenEditNew(false);
        fetchData();
      })
      .catch(() => {
        showMessage("Greška prilikom brisanja događaja.", MessageType.ERROR);
      });
  };

  const handleDelete = (id) => {
    confirm({
      title: "Brisanje događaja",
      description: "Da li ste sigurni da želite da obrišete događaj?",
      confirmationText: "Potvrdi",
      cancellationText: "Odustani",
      confirmationButtonProps: {
        variant: "contained",
        color: "primary",
      },
    })
      .then(eventDelete)
      .catch(() => {});
  };

  const MonthEvent = ({ event }) => {
    return (
      <div>
        <div>
          {moment(event.start).format("HH:mm")} {event.location.name}
        </div>
        <div>{event.team.name}</div>
        {event.eventType === "GAME" && <div>{event.otherTeam?.name}</div>}
      </div>
    );
  };

  return (
    <div ref={myRef}>
      <Grid container className={classes.gridRow}>
        <Grid container xs={6} spacing={1} className={classes.multiselect}>
          {["Treneri", "Lokacija", "Grupe", "Vrsta"].map((itemName, i) => (
            <Grid key={i} item xs={3}>
              <Multiselect name={itemName} options={allOptions[i]} setFilter={setFilter} index={i} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={2} spacing={1}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker variant="inline" autoOk views={["year", "month"]} openTo="year" label="Izaberi datum" format={"MMMM YYYY."} value={newDate} onChange={(value) => setNewDate(value._d)} />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }}>
          {/* <Button onClick={() => editNewEvent()} size="small" variant="contained" color="primary" startIcon={<AddBox />}>
            Novi događaj
          </Button> */}
        </Grid>
        <Grid item xs={12} className={classes.calendar}>
          <Calendar
            // components={{
            //   event: MonthEvent,
            // }}
            eventPropGetter={(event, start, end, isSelected) => {
              let newStyle = {};

              if (event.eventStatus === EventStatus.STARTED) {
                newStyle.backgroundColor = "orange";
              } else if (event.eventStatus === EventStatus.DONE) {
                newStyle.backgroundColor = "green";
              }

              return {
                className: "",
                style: newStyle,
              };
            }}
            date={newDate}
            formats={{ weekdayFormat: (date, culture, localizer) => localizer.format(date, "dddd", culture) }}
            events={events}
            onNavigate={(date) => setNewDate(moment(date).toDate())}
            defaultDate={moment().toDate()}
            popup={true}
            tooltipAccessor={(e) => e.desc}
            localizer={localizer}
            onSelectEvent={(e) => editNewEvent(parseEvent(e))}
            messages={{
              today: "Danas",
              previous: "Nazad",
              next: "Napred",
              month: "Mesec",
              week: "Nedelja",
              day: "Dan",
              agenda: "Plan",
              date: "Datum",
              time: "Vreme",
              event: "Događaj",
              allDay: "Ceo dan",
              yesterday: "Juče",
              tomorrow: "Sutra",
              showMore: (count) => `jos +${count}`,
              noEventsInRange: "Nema događaja u ovom vremenskom periodu",
            }}
          />
        </Grid>
      </Grid>
      <EventViewDialog open={openEditNew} close={() => setOpenEditNew(false)} eventId={eventObj?.id} deleteAction={handleDelete} />
      {/* <Dialog open={openEditNew} onClose={() => setOpenEditNew(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>{eventObj.id ? "Izmeni događaj" : "Novi događaj"}</Grid>
            <Grid item>
              {eventObj.id && (
                <IconButton onClick={() => setOpenDelete(true)} aria-label="delete" size="medium">
                  <DeleteIcon fontSize="large" color="secondary" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <Autocomplete
                options={allOptions[3]}
                getOptionLabel={(option) => option.title}
                value={eventObj.eventType}
                onChange={(e, value) => setEventObj({ ...eventObj, eventType: value ? value : parseOption() })}
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Vrsta" required fullWidth />}
              />
            </Grid>
            <Grid item sm={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  variant="inline"
                  inputVariant="outlined"
                  ampm={false}
                  margin="normal"
                  id="startTime"
                  name="startTime"
                  label="Početak"
                  value={eventObj.startTime}
                  autoOk
                  format="DD.MM.YYYY. HH:mm"
                  onChange={(value) => {
                    setEventObj({ ...eventObj, startTime: value._d });
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                options={allOptions[1]}
                getOptionLabel={(option) => option.title}
                value={eventObj.location}
                onChange={(e, value) => setEventObj({ ...eventObj, location: value ? value : parseOption() })}
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Lokacija" required fullWidth />}
              />
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                options={allOptions[2]}
                getOptionLabel={(option) => option.title}
                value={eventObj.team}
                onChange={(e, value) => setEventObj({ ...eventObj, team: value ? value : parseOption() })}
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Tim" required fullWidth />}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="note"
                name="note"
                label="Napomena"
                value={eventObj.note}
                onChange={(e) => setEventObj({ ...eventObj, [e.target.name]: e.target.value })}
                required
                fullWidth
              />
            </Grid>
            {eventObj.eventType?.id === "GAME" && (
              <Grid item sm={6}>
                <Autocomplete
                  options={allOptions[2]}
                  getOptionLabel={(option) => option.title}
                  value={eventObj.otherTeam}
                  onChange={(e, value) => setEventObj({ ...eventObj, otherTeam: value ? value : parseOption() })}
                  renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Drugi tim" required fullWidth />}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpenEditNew(false)}>
            Odustani
          </Button>
          <Button variant="contained" color="primary" onClick={saveEvent}>
            Snimi
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Da li ste sigurni da želite da obrišete?</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete(eventObj.id)} color="primary" autoFocus>
            Da
          </Button>
          <Button onClick={() => setOpenDelete(false)} color="primary">
            Ne
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function Multiselect({ name, options, setFilter, index }) {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(options);
  }, [options]);

  const handleChange = (event, index) => {
    setFilter((prevState) => {
      const newState = prevState;
      prevState[index] = event;
      return [...newState];
    });
    setSelected(event);
  };

  const localization = {
    selectSomeItems: name,
    allItemsAreSelected: name + " - sve",
    selectAll: "Sve",
    search: "Pretraga",
  };
  return (
    <React.Fragment>
      <MultiSelect options={options ? options : []} value={selected} overrideStrings={localization} onChange={(e) => handleChange(e, index)} />
    </React.Fragment>
  );
}
