import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function Exercises() {
  const { token, showMessage, handleErrorMessage } = useAuth();
  const [exercisesType, setExercisesTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(Endpoints.EXERCISE_TYPE, headers(token))
      .then((response) => {
        const parseData = response.data.map(({ id, name, description }) => ({ id, name, description }));
        setExercisesTypes(parseData);
      })
      .catch((e) => handleErrorMessage(showMessage, e))
      .finally((e) => setLoading(false));
  };

  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    { field: "id", hidden: true },
    { title: "Naziv", field: "name" },
    { title: "Opis", field: "description" },
  ];

  return <ReactTable columns={columns} data={exercisesType} loading={loading} reload={fetchData} opt={{ title: "Tipovi vežbe", item: "exercise_Type", filter: false }} />;
}
