import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { headers, headersDelete, useAuth } from "../../../../../context/auth";
import { getString } from "../../../../../lang/Local";
import Endpoints from "../../../../../utils/Endpoints";
import MessageType from "../../../../../utils/MessageType";
import useStyles from "./RolePermissionStyle";

export default function RolePermissions() {
  const classes = useStyles();
  const { token, showMessage } = useAuth();

  const [permissionHave, setPermissionHave] = useState([]);
  const [permissionNotHave, setPermissionNotHave] = useState([]);

  const { id } = useParams();

  const fetchData = () => {
    axios
      .get(Endpoints.ROLE_PROFILE + "/" + id, headers(token))
      .then((response) => {
        setPermissionHave(response.data.permissionHave);
        setPermissionNotHave(response.data.permissionNotHave);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = (value, create) => () => {
    const rolePermission = {
      roleID: id,
      permissionID: value,
    };
    if (create) {
      axios
        .post(Endpoints.ROLE_PERMISSIONS, rolePermission, headers(token))
        .then((result) => {
          showMessage("Uspešno dodato pravo.", MessageType.SUCCESS);
          fetchData();
        })
        .catch((e) => {
          showMessage("Greška kod čuvanja.", MessageType.ERROR);
        });
    } else {
      axios
        .delete(
          Endpoints.ROLE_PERMISSIONS,
          headersDelete(token, rolePermission)
        )
        .then((result) => {
          showMessage("Uspešno uklonjeno pravo .", MessageType.SUCCESS);
          fetchData();
        })
        .catch((e) => {
          showMessage("Greška kod brisanja.", MessageType.ERROR);
        });
    }
  };

  const permissionList = (permissons, create) => (
    <Paper className={classes.paper}>
      <List dense>
        {permissons.map((permission) => {
          return (
            <ListItem
              key={permission.id}
              role="listitem"
              button
              onClick={handleToggle(permission.id, create)}
            >
              <ListItemText
                primary={getString(`permission.${permission.code}`)}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Paper className={classes.paper_global}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Typography variant="h6" className={classes.paper_title}>
            Nema aplikativna prava
          </Typography>
          {permissionList(permissionNotHave, true)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            {/* <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≪
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≫
            </Button> */}
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6" className={classes.paper_title}>
            Ima aplikativna prava
          </Typography>
          {permissionList(permissionHave, false)}
        </Grid>
      </Grid>
    </Paper>
  );
}
