import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function Clubs(props) {
  const { token } = useAuth();
  const [clubs, setClubs] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.CLUB, headers(token))
      .then((result) => {
        setClubs(result.data);
        setLoading(false);
      })
      .catch((e) => {});
  };

  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    { title: "Ime", field: "name" },
    { title: "Grad", field: "city" },
    { title: "Adresa", field: "adressNumber" },
    { title: "Žiro račun", field: "transferAccount" },
  ];

  return (
    <ReactTable
      columns={columns}
      data={clubs}
      loading={loading}
      reload={fetchData}
      opt={{ title: "Klubovi", item: "club", filter: false }}
    />
  );
}
