import { SnackbarProvider } from "notistack";
import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./lang/i18n";
import Routers from "./Routers";
import Store from "./utils/Store";

export default function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Store>
        <I18nextProvider i18n={i18n}>
          <Routers />
        </I18nextProvider>
      </Store>
    </SnackbarProvider>
  );
}
