import { Typography } from "@material-ui/core";
import { PanelContainer } from "./styled";

export function Panel({ title, rightButton }) {
  return (
    <PanelContainer elevation={1}>
      <Typography variant="h6">{title}</Typography>
      {rightButton}
    </PanelContainer>
  );
}
