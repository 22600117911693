import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import React, { useState } from "react";
import { useParams } from "react-router";
import TeamInfo from "../team/TeamInfo";
import TeamMember from "./TeamMember";
import useStyles from "./TeamStyle";

const TAB_INFO = 0;
const TAB_PLAYERS = 1;

export default function Team() {
  const classes = useStyles();
  let { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TAB_INFO);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="icon tabs example">
            <Tab label="Info" icon={<InfoIcon />} />
            <Tab label="Igrači" icon={<PeopleIcon />} disabled={!id} />
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_INFO && <TeamInfo setSelectedTab={setSelectedTab} />}
      {selectedTab === TAB_PLAYERS && <TeamMember />}
    </div>
  );
}
