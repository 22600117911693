import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import { EventTypes } from "../../../../utils/enum/EnumEventType";
import { dateTimeView, DATE_FORMAT1, handleErrorMessage } from "../../../../utils/Utils";
import { TextTitle } from "./styled";

export default function EventViewDialog({ open, close, eventId, deleteAction }) {
  const { token, showMessage } = useAuth();
  const [event, setEvent] = useState();
  const [member, setMembers] = useState([]);

  useEffect(() => {
    if (eventId) {
      axios
        .get(Endpoints.EVENT + "/" + eventId, headers(token))
        .then(({ data }) => {
          setEvent(data);
          setMembers(data.memberEventList.map((me) => me.member.id));
        })
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  }, [eventId]);

  if (!event) {
    return null;
  }

  const isGame = event.eventType === EventTypes.GAME;

  return (
    <Dialog open={open} minWidth="md" onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>Prikaz događaja</Grid>
          <Grid item>
            {event.id && deleteAction && (
              <IconButton onClick={deleteAction} aria-label="delete" size="medium">
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item sm={12}>
            <TextTitle>Datum i vreme:</TextTitle>
            <Typography>{dateTimeView(event.startTime).format(DATE_FORMAT1)}</Typography>
          </Grid>
          <Grid container item sm={12}>
            <TextTitle>Tim:</TextTitle>
            <Typography>{event.team.name}</Typography>
          </Grid>
          {isGame && (
            <Grid container item sm={12}>
              <TextTitle>Drugi tim:</TextTitle>
              <Typography>{event.otherTeam?.name}</Typography>
            </Grid>
          )}
          <Grid container item sm={12}>
            <TextTitle>Lokacija:</TextTitle>
            <Typography>
              {event.location.name} ({event.location.addressNumber})
            </Typography>
          </Grid>
          <Grid container item sm={12}>
            <TextTitle> Spisak članova:</TextTitle>
          </Grid>
          <Grid container item sm={12}>
            <TextTitle>{event.team.name}</TextTitle>
          </Grid>
          <Grid container item sm={12}>
            <div>
              {event.team.memberTeamList
                ?.filter((m) => member.includes(m.member.id))
                ?.map((m, index) => (
                  <div key={m.id}>
                    <div>
                      {index + 1}. {m.member.fullName}
                    </div>
                  </div>
                ))}
            </div>
          </Grid>
          {isGame && (
            <>
              <Grid container item sm={12}>
                <TextTitle>{event.otherTeam.name}</TextTitle>
              </Grid>
              <Grid container item sm={12}>
                <div>
                  {event.otherTeam.memberTeamList
                    ?.filter((m) => member.includes(m.member.id))
                    ?.map((m, index) => (
                      <div key={m.id}>
                        <div>
                          {index + 1}. {m.member.fullName}
                        </div>
                      </div>
                    ))}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          Odustani
        </Button>
      </DialogActions>
    </Dialog>
  );
}
