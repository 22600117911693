import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
  },
  section: {
    margin: theme.spacing(3),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  padding_2: {
    padding: theme.spacing(2),
  },
  switch: {
    padding: theme.spacing(3),
  },
  container: {
    position: "relative",
    width: "90%",
    paddingTop: theme.spacing(2),
    maxWidth: "400px",
    "&:hover img": {
      opacity: "0.5",
    },
    "&:hover div": {
      opacity: "1",
    },
  },
  overlay: {
    position: "absolute",
    top: "40%",
    bottom: "0",
    left: "21%",
    right: "0",
    opacity: "0",
  },
  icon: {
    color: "white",
    fontSize: "100px",
    position: "absolute",
    top: "50%",
    left: "50%",
    textAlign: "center",
  },
  image: {
    width: "100%",
    height: "160px",
    borderRadius: theme.spacing(2),
  },
  upload: {
    width: "50%",
    color: "black",
  },
}));

export default useStyles;
