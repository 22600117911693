import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import { currencyFormat, dateTimeView, DATE_FORMAT1 } from "../../../../utils/Utils";
import ReactTable from "../../../system/ReactTable";
import useEffectOnce from "../../../system/useEffectOnce";

export default function IncomeExpense() {
  const { token } = useAuth();
  const [dataTable, setDataTable] = useState([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(true);

  const columns = [
    { title: "Naslov", field: "title" },
    { title: "Datum", field: "date" },
    { title: "Tip", field: "type" },
    { title: "Iznos", field: "amount" },
  ];

  const typeLabel = (status) => {
    switch (status) {
      case "FEE":
        return "Članarina";
      case "EVENT":
        return "Događaj";
      default:
        return "";
    }
  };

  const fetchData = () => {
    setLoading(true);
    const incomeRequest = axios.get(Endpoints.INCOME, headers(token));
    const expenseRequest = axios.get(Endpoints.EXPENSE, headers(token));
    axios
      .all([incomeRequest, expenseRequest])
      .then(
        axios.spread((responseIncome, responseExpense) => {
          let sumTotal = 0;
          const data = [];
          responseIncome.data.forEach((income) => {
            sumTotal += income.amount;
            data.push({
              positive: <AddIcon style={{ color: "green" }} />,
              id: `I-${income?.id}`,
              title: income.name,
              date: dateTimeView(income.dateCreate).format(DATE_FORMAT1),
              type: typeLabel(income.incomeType),
              amount: currencyFormat(income.amount),
            });
          });

          responseExpense.data.forEach((expense) => {
            sumTotal -= expense.amount;
            data.push({
              positive: false,
              id: `E-${expense?.id}`,
              title: expense.name,
              date: dateTimeView(expense.dateCreate).format(DATE_FORMAT1),
              type: typeLabel(expense.incomeType),
              amount: currencyFormat(expense.amount),
            });
          });

          setDataTable(data);
          setTotal(sumTotal);
        })
      )
      .catch((e) => {})
      .finally(() => setLoading(false));
  };

  useEffectOnce(() => {
    fetchData();
  });

  return (
    <ReactTable
      columns={columns}
      data={dataTable}
      loading={loading}
      opt={{
        title: `Total: ${currencyFormat(total)} din`,
        item: "dataTable",
        filter: true,
        hideAdd: true,
        hideEdit: true,
        rowStyle: (rowData) => ({ backgroundColor: rowData.positive ? "#74a75d" : "#e07575" }),
      }}
    />
  );
}
