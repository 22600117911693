import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function Exercises() {
  const { token, showMessage, handleErrorMessage } = useAuth();
  const [exercises, setExercises] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    fetchData();
  });

  const fetchData = () => {
    setLoading(true);
    axios
      .get(Endpoints.EXERCISE, headers(token))
      .then((res) => {
        const parseData = res.data.map((obj) => {
          return {
            ...obj,
            exerciseType: obj.exerciseTypeExerciseList.map(({ exerciseType }) => exerciseType.name).join(", "),
          };
        });
        setExercises(parseData);
      })
      .catch((e) => handleErrorMessage(showMessage, e))
      .finally(() => setLoading(false));
  };

  const columns = [
    { title: "Naziv", field: "name" },
    { title: "Tip", field: "exerciseType" },
  ];

  return <ReactTable columns={columns} data={exercises} loading={loading} reload={fetchData} opt={{ title: "Vežbe", item: "exercise", filter: false }} />;
}
