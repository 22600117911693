import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import React, { useState } from "react";
import useStyles from "./FinanceStyle";
import IncomeExpense from "./income-expense/IncomeExpense";
import ReviewPayments from "./review-payment/ReviewPayments";

const TAB_REVIEW_PAYMENTS = 0;
const TAB_INCOME_EXPENSE = 1;

export default function Finance() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(TAB_REVIEW_PAYMENTS);

  const handleChange = (event, newValue) => setSelectedTab(newValue);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth" scrollButtons="on" indicatorColor="primary" textColor="primary" aria-label="icon tabs example">
            <Tab label="Predaja članarine" icon={<InfoIcon />} />
            <Tab label="Prihodi/rashodi" icon={<PeopleIcon />} />
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_REVIEW_PAYMENTS && <ReviewPayments />}
      {selectedTab === TAB_INCOME_EXPENSE && <IncomeExpense />}
    </div>
  );
}
