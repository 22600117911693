import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { handleErrorMessage } from "../../../utils/Utils";
import UploadImage from "../../system/upload-image/UploadImage";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./ClubStyle";

export default function Club() {
  const { token, showMessage } = useAuth();

  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [club, setClub] = useState();
  const [legalDocuments, setLegalDocuments] = useState([]);

  useEffectOnce(() => {
    axios
      .get(Endpoints.LEGAL_DOCUMENTS, headers(token))
      .then((res) => {
        setLegalDocuments(res.data);
      })
      .catch((e) => handleErrorMessage(showMessage, e));

    if (id) {
      axios
        .get(Endpoints.CLUB + "/" + id, headers(token))
        .then((response) => {
          setClub(response.data);
        })
        .catch((e) => {});
    }
  });

  const save = (e) => {
    const clubNew = {
      ...club,
      policyId: club.policy?.id,
    };
    axios
      .post(Endpoints.CLUB, clubNew, headers(token))
      .then((result) => {
        history.push("/");
      })
      .catch((e) => {});
  };

  const handleChange = (e) => setClub({ ...club, [e.target.name]: e.target.value });

  if (!club) {
    return null;
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Klub
          </Typography>
          <Grid item justify="center" className={classes.imageContainer}>
            <UploadImage object={club} setObject={setClub} />
          </Grid>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" margin="normal" id="name" name="name" label="Naziv" value={club?.name ?? ""} onChange={handleChange} required fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" margin="normal" required id="adressNumber" name="adressNumber" label="Adresa" value={club?.adressNumber ?? ""} onChange={handleChange} fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" margin="normal" required id="city" name="city" label="Grad" value={club?.city ?? ""} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField variant="outlined" margin="normal" id="transferAccount" name="transferAccount" label="Žiro račun" value={club?.transferAccount ?? ""} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="presidentFirstName"
                name="presidentFirstName"
                label="Predsednik ime"
                value={club?.presidentFirstName ?? ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="presidentLastName"
                name="presidentLastName"
                label="Predsednik prezime"
                value={club?.presidentLastName ?? ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="secretarFirstName"
                name="secretarFirstName"
                label="Sekretar ime"
                value={club?.secretarFirstName ?? ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                id="secretarLastName"
                name="secretarLastName"
                label="Sekretar prezime"
                value={club?.secretarLastName ?? ""}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="combo-box-demo"
                  options={legalDocuments}
                  getOptionLabel={(option) => option.name}
                  value={club.policy}
                  onChange={(event, value) =>
                    setClub({
                      ...club,
                      policy: value ? value : {},
                    })
                  }
                  renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="policy" name="policy" label="Politika kluba" fullWidth />}
                />
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button variant="contained" onClick={(e) => history.push("/")} className={classes.button}>
              {getString("common.cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={save} className={classes.button}>
              {getString("common.save")}
            </Button>
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
}
