import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { handleErrorMessage } from "../../../utils/Utils";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function Teams() {
  const { token, showMessage } = useAuth();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.TEAM, headers(token))
      .then((result) => {
        let teamList = result.data.map((team) => {
          return {
            id: team.id,
            name: team.name,
            headCoach: team.headCoach?.fullName ?? "",
            assistCoach: team.assistCoach?.fullName ?? "",
            note: team.note,
            memberNumber: team.memberTeamList.length,
          };
        });
        setTeams(teamList);
        setLoading(false);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  useEffectOnce(fetchData);

  const columns = [
    { title: "Naziv", field: "name" },
    { title: "Glavni trener", field: "headCoach" },
    { title: "Pomoćni trener", field: "assistCoach" },
    { title: "Broj članova", field: "memberNumber" },
    { title: "Napomena", field: "note" },
  ];

  return <ReactTable columns={columns} data={teams} loading={loading} reload={fetchData} opt={{ title: "Timovi", item: "team", filter: false }} />;
}
