import { useImperativeHandle, useState } from "react";
import { MUIEditorState } from "react-mui-draft-wysiwyg";

export default function RichTextService({ muiEditorRef }) {
  useImperativeHandle(muiEditorRef, () => ({
    get: () => editorState,
    set: (state) => setEditorState(state),
  }));

  const [editorState, setEditorState] = useState(MUIEditorState.createEmpty());

  const onChange = (newState) => setEditorState(newState);

  return { editorState, onChange };
}
