import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoIcon from "@material-ui/icons/Info";
import React, { useState } from "react";
import { useParams } from "react-router";
import LocationInfo from "./LocationInfo";
import LocationPrice from "./LocationPrice";
import useStyles from "./LocationStyle";

const TAB_INFO = 0;
const TAB_PRICE = 1;

export default function Location() {
  const classes = useStyles();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TAB_INFO);

  return (
    <div className={classes.root}>
      <Paper>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon tabs example">
            <Tab label="Info" icon={<InfoIcon />} />
            {/* <Tab label="Cene" icon={<LocalOfferIcon />} disabled={!id} /> */}
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_INFO && <LocationInfo />}
      {selectedTab === TAB_PRICE && <LocationPrice />}
    </div>
  );
}
