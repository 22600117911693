import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export function Posts() {
  const { token, showMessage } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.POSTS, headers(token))
      .then((result) => setPosts(result.data))
      .catch((e) => showMessage("Greška dohvatanja podataka", MessageType.ERROR))
      .finally(() => setLoading(false));
  };

  useEffectOnce(fetchData);

  const columns = [
    { title: "Naslov", field: "title" },
    { title: "Podnaslov", field: "subtitle" },
    { title: "Kreirao", field: "userCreator.fullName" },
    { title: "Vreme kreiranja", field: "dateCreate", render: (rowData) => moment(rowData.dateCreate).format("DD.MM.YYYY. HH:mm") },
  ];

  return (
    <ReactTable
      columns={columns}
      data={posts}
      loading={loading}
      reload={fetchData}
      opt={{
        title: "Obaveštenja",
        item: "post",
        filter: false,

        path: Endpoints.POSTS,
      }}
    />
  );
}
