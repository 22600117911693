import { encode } from "base64-arraybuffer";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import moment from "moment";
import { useEffect } from "react";
import MessageType from "./MessageType";

export const axiosConfig = (token, params) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    params: params ? params : {},
  };
};

const handleSuccessMessage = (showMessage, response) => {
  if (response.data.data.successes) {
    response.data.data.successes.forEach((e) => {
      showMessage(e.message, MessageType.SUCCESS);
    });
  } else {
    showMessage(response.toString(), MessageType.SUCCESS);
  }
};

const handleErrorMessage = (showMessage, error) => {
  const errorObj = error?.response?.data;
  if (error?.response?.status === 401) {
    showMessage("Your previous session has expired. Please login.", MessageType.ERROR);
  } else if (errorObj) {
    errorObj.length = 5;
    const responseMessage = errorObj.map((e) => e.message + "\n");
    showMessage(responseMessage, MessageType.ERROR);
  } else {
    showMessage(error.toString(), MessageType.ERROR);
  }
};

export const currencyFormat = (num) => num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const formatter = new Intl.NumberFormat("sr-RS", {
  style: "currency",
  currency: "RSD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const dateTimeView = (rowDate) => {
  if (!rowDate) return undefined;
  let month = rowDate[1] < 10 ? "0" + rowDate[1] : rowDate[1];
  let day = rowDate[2] < 10 ? "0" + rowDate[2] : rowDate[2];
  let hour = rowDate[3] < 10 ? "0" + rowDate[3] : rowDate[3];
  let minute = rowDate[4] < 10 ? "0" + rowDate[4] : rowDate[4];
  let second = rowDate[5] && rowDate[5] < 10 ? "0" + rowDate[5] : rowDate[5];
  const date = [rowDate[0], month - 1, day, hour, minute, second ?? 0];
  return moment(date);
};

export const DATE_FORMAT1 = "DD.MM.YYYY. HH:mm:ss";
export const DATE_FORMAT3 = "YYYY-MM-DDTHH:mm:ss";

// eslint-disable-next-line
const useEffectOnce = (effect) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

export const richTextValue = (ref) => ref.current.get();

export const richTextStateToHtml = (state) => {
  const content = state.getCurrentContent();
  return content.hasText() ? stateToHTML(content) : "";
};

export const richTextStateFromHTML = (html) => {
  const contentState = stateFromHTML(html);
  return EditorState.createWithContent(contentState);
};

export const fileToByteArray = async (file) => {
  const buffer = await file.arrayBuffer();
  var base64String = encode(buffer);
  return base64String;
};

export const monthsInSerbian = [
  { id: 1, name: "Januar" },
  { id: 2, name: "Februar" },
  { id: 3, name: "Mart" },
  { id: 4, name: "April" },
  { id: 5, name: "Maj" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Avgust" },
  { id: 9, name: "Septembar" },
  { id: 10, name: "Oktobar" },
  { id: 11, name: "Novembar" },
  { id: 12, name: "Decembar" },
];

export { handleErrorMessage, handleSuccessMessage, useEffectOnce };
