import { Avatar, Box, Typography } from "@material-ui/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { dateTimeView, handleErrorMessage } from "../../../utils/Utils";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";
import MemberBookPdf from "./../../system/pdf/MemberBookPdf";

export default function Members() {
  const { token, showMessage } = useAuth();
  const [members, setMembers] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`${Endpoints.MEMBER}/all`, headers(token))
      .then((response) => {
        const membersArray = response.data.map((member) => ({
          id: member.id,
          imageId: member.image?.id,
          fullName: member.fullName,
          username: member.username,
          birthDate: member.birthDate ? dateTimeView(member.birthDate).format("DD.MM.YYYY.") : "",
          teamUser: member?.memberTeamList?.map(({ team }) => team.name + " - " + team.headCoach.fullName + "\n"),
          mainContactPhone: member.mainContactPhone,
          email: member.email,
          clubNumberId: member.clubNumberId,
          signMemberBook: member?.memberBookList?.length > 0 ? "Da" : "Ne",
        }));
        setMembers(membersArray);
        setLoading(false);
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };
  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    {
      title: "Slika",
      field: "image",
      filtering: false,
      render: ({ imageId, fullName }) => <Avatar loading="lazy" alt={fullName} src={imageId ? Endpoints.FILES + "/image/" + imageId : ""} />,
    },
    {
      title: "Ime i prezime",
      field: "fullName",
    },
    {
      title: "Korisničko ime",
      field: "username",
    },

    { title: "Datum rođenja", field: "birthDate" },
    { title: "Grupa - trener", field: "teamUser" },
    // { title: "Status", field: "memberStatus" },
    { title: "Telefon", field: "mainContactPhone" },
    { title: "Email", field: "email" },
    { title: "Evidencioni broj", field: "clubNumberId" },
    {
      title: "Knjiga članova",
      field: "signMemberBook",
    },
  ];

  return (
    <ReactTable
      columns={columns}
      data={members}
      loading={loading}
      reload={fetchData}
      opt={{
        title: (
          <Box display="flex" justifyContent="space-between" alignItems="center" width="250px">
            <Typography variant="h6">Članovi </Typography>
            <div>
              <PDFDownloadLink style={{ color: "#5DAEBF" }} document={<MemberBookPdf users={members} />} fileName="Knjiga članova.pdf">
                {({ blob, url, loading, error }) => (loading ? "Loading..." : "Knjiga članova")}
              </PDFDownloadLink>
            </div>
          </Box>
        ),
        item: "member",
        filter: true,
      }}
    />
  );
}
