import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  section: {
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  main: {
    padding: theme.spacing(2),
  },

  buttons: { textAlign: "right" },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  multiselect: {
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
