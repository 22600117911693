import MomentUtils from "@date-io/moment";
import { Box, Button, Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import { axiosConfig, DATE_FORMAT3, dateTimeView } from "../../../../utils/Utils";
import useStyles from "./AttendenceStyle";

export default function Attendence() {
  const { token } = useAuth();
  const classes = useStyles();
  const [eventData, setEventData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [dataExport, setDataExport] = useState();

  const pullData = (id, fullName) => {
    return { id, fullName };
  };

  const rows = (data) => {
    return data.members.map((member) => pullData(member.id, member.fullName));
  };

  const makeData = (data) => {
    let rowArray = [];
    let finalArray = [];
    data.events.map((event) => {
      data.members.map((member) => {
        rowArray.push(event.members.includes(member.id));
      });
      finalArray.push(rowArray);
      rowArray = [];
    });
    return finalArray;
  };

  const makeStats = (data) => {
    let appearencePerPlayer = 0;
    let overallAppearences = [];
    rows(data).map((row, index) => {
      makeData(data).map((column) => {
        if (column[index]) appearencePerPlayer++;
      });
      overallAppearences.push(appearencePerPlayer);
      appearencePerPlayer = 0;
    });
    return overallAppearences;
  };

  const showPresenceRelation = (data, index) => {
    return `${makeStats(data)[index]} / ${data.events.length}`;
  };

  const showPresencePercent = (data, index) => {
    let calculation = (makeStats(data)[index] / data.events.length) * 100;
    return (isNaN(calculation) ? 0 : calculation.toFixed(2)) + "%";
  };

  const fetchDataGroups = () => {
    axios
      .get(Endpoints.TEAM, headers(token))
      .then((result) => {
        let groupsArray = result.data.map((team) => {
          return {
            id: team.id,
            title: team.name,
          };
        });
        setTeams(groupsArray);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchStats = (team, startDate, endDate) => {
    axios
      .get(Endpoints.STATISTICS + "/memberByEvent", axiosConfig(token, { teamId: team.id, dateFrom: startDate, dateTo: endDate }))
      .then((result) => {
        setEventData(result.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchDataGroups();
  }, []);

  useEffect(() => {
    if (team && startDate && endDate) {
      fetchStats(team, startDate, endDate);
    }
  }, [team, startDate, endDate]);

  useEffect(() => {
    if (eventData) {
      const eventDataNew = [];

      const events = eventData?.events?.map((e) => dateTimeView(e?.date)?.format("DD.MM.YYYY")) ?? [];
      eventDataNew.push(["Ime i prezime", ...events]);
      eventData?.members?.forEach((m) => {
        const eventMember = [];
        eventData?.events?.forEach((ev) => {
          eventMember.push(ev.members.includes(m.id) ? "Da" : "Ne");
        });
        eventDataNew.push([m.fullName, ...(eventMember ?? [])]);
      });

      setDataExport(eventDataNew);
    }
  }, [eventData]);

  return (
    <Grid container item className={classes.mainGrid}>
      {dataExport && (
        <Box>
          <Button color="primary" variant="contained">
            <CSVLink data={dataExport} filename="prisutnost.csv" style={{ color: "white", textDecoration: "none" }}>
              Prisutnost izveštaj
            </CSVLink>
          </Button>
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="combo-box-demo"
            options={teams}
            getOptionLabel={(option) => option?.title ?? ""}
            value={team ?? ""}
            onChange={(event, value) => setTeam(value)}
            renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="group" name="group" label="Tim" fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              disableFuture
              autoOk
              clearLabel={false}
              openTo="year"
              margin="normal"
              label="Datum od"
              format={"DD.MM.YYYY."}
              value={startDate}
              InputAdornmentProps={{ position: "start", variant: "standard" }}
              onChange={(date) => {
                setStartDate(moment(date).format(DATE_FORMAT3));
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={3}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              disableFuture
              autoOk
              clearLabel={false}
              openTo="year"
              margin="normal"
              label="Datum do"
              format={"DD.MM.YYYY."}
              value={endDate}
              InputAdornmentProps={{ position: "start", variant: "standard" }}
              onChange={(date) => {
                setEndDate(moment(date).format(DATE_FORMAT3));
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            {eventData ? (
              <TableRow>
                <TableCell>Ime i prezime</TableCell>
                {eventData?.events
                  ?.sort((a, b) => dateTimeView(a.date)?.valueOf() - dateTimeView(b.date)?.valueOf())
                  .map((event) => (
                    <TableCell key={event.id} align="center">
                      {event.date ? dateTimeView(event.date).format("DD.MM.") : ""}
                    </TableCell>
                  ))}
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center">Izaberi grupu i period</TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {eventData &&
              rows(eventData).map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row?.fullName}</TableCell>
                  {eventData &&
                    makeData(eventData).map((column, columnIndex) => (
                      <TableCell key={columnIndex} align="center">
                        {column[index] ? <CheckIcon color="primary" /> : <ClearIcon color="error" />}
                      </TableCell>
                    ))}
                  <TableCell align="center">{eventData && showPresenceRelation(eventData, index)}</TableCell>
                  <TableCell align="center">{eventData && showPresencePercent(eventData, index)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
