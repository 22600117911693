import Paper from "@material-ui/core/Paper";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { useParams } from "react-router";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { eventTypeLabel } from "../../../utils/enum/EnumEventType";
import { dateTimeView, handleErrorMessage } from "../../../utils/Utils";
import { localization, tableIcons } from "../../system/MaterialTableUtils";
import useEffectOnce from "../../system/useEffectOnce";

export default function MemberEvent() {
  const { token, showMessage } = useAuth();
  const { id } = useParams();
  const [events, setEvents] = useState([]);

  const columns = [
    { field: "eventTypeName", title: "Vrsta događaja" },
    { field: "groupName", title: "Grupa" },
    { field: "locationName", title: "Lokacija" },
    { field: "startTime", title: "Početak" },
    { field: "rating", title: "Rejting" },
    { field: "comment", title: "Komentar" },
  ];

  const fetchData = () => {
    axios
      .get(Endpoints.EVENT + "/memberEvents/" + id, headers(token))
      .then((result) => {
        let parseEvents = result.data.map((me) => {
          return {
            id: me.event.id,
            eventTypeName: eventTypeLabel(me.event.eventType ?? ""),
            groupName: me.event.team ? me.event.team.name : "",
            locationName: me.event.location.name ?? "",
            startTime: dateTimeView(me.event.startTime).format("DD.MM.YYYY."),
            rating: me.rating,
            comment: me.comment,
          };
        });
        setEvents(parseEvents);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  useEffectOnce(fetchData);

  return (
    <Paper>
      <MaterialTable
        icons={tableIcons}
        title={""}
        columns={columns}
        data={events}
        localization={localization}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
        }}
      />
    </Paper>
  );
}
