import { Avatar } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { handleErrorMessage } from "../../../utils/Utils";
import ReactTable from "../../system/ReactTable";
import useEffectOnce from "../../system/useEffectOnce";

export default function Users() {
  const { token, showMessage } = useAuth();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.USER, headers(token))
      .then((result) => {
        const usersArray = result.data.map((user) => {
          return {
            id: user.id,
            imageId: user.image?.id,
            fullName: user.fullName,
            contactPhone: user.contactPhone,
            licenceNumber: user.licenceNumber,
            email: user.email,
          };
        });
        setUsers(usersArray);
        setLoading(false);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  useEffectOnce(() => {
    fetchData();
  });

  const columns = [
    {
      title: "Slika",
      field: "image",
      filtering: false,
      render: ({ imageId, fullName }) => <Avatar alt={fullName} loading="lazy" src={imageId ? Endpoints.FILES + "/image/" + imageId : ""} />,
    },
    { title: "Ime i prezime", field: "fullName" },
    { title: "Telefon", field: "contactPhone" },
    { title: "Email", field: "email" },
  ];

  return <ReactTable columns={columns} data={users} loading={loading} reload={fetchData} opt={{ title: "Treneri", item: "user", filter: false }} />;
}
