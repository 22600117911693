import { Avatar, Divider, ListItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import useStyles from "./HeaderStyle";

export default function Header() {
  const { deleteAuthData, user } = useAuth();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { push } = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {/* <ListItem
        button
        onClick={() => {
          handleClose();
          history.push("/profile");
        }}
      >
        <ListItemIcon>
          <PersonIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Profil" />
      </ListItem> */}
      {/* <Divider /> */}
      <ListItem
        button
        onClick={() => {
          handleClose();
          push(`/club/${user?.club?.id}`);
        }}
      >
        <ListItemIcon>
          <BusinessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Klub" />
      </ListItem>
      <Divider />
      <ListItem button onClick={deleteAuthData}>
        <ListItemIcon>
          <ExitToAppIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Izloguj me" />
      </ListItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <AppBar position="absolute" className={classes.header}>
        <Toolbar>
          <Grid container>
            <Grid item container xs={8}>
              <Link to="/" className={classes.link}>
                <IconButton color="inherit">
                  <Typography className={classes.title} variant="h5" noWrap>
                    Evico
                  </Typography>
                </IconButton>
              </Link>
            </Grid>
            <Grid container item xs={4} direction="row" justify="flex-end" alignItems="center">
              <Grid item>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <IconButton style={{ color: "white" }} onClick={handleClick}>
                    {user?.image ? <Avatar className={classes.iconSmall} loading="lazy" alt={user?.fullName} src={Endpoints.FILES + "/image/" + user?.image?.id} /> : <AccountCircleIcon />}
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </React.Fragment>
  );
}
