import PageviewIcon from "@material-ui/icons/Pageview";
import axios from "axios";
import React, { useState } from "react";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import { dateTimeView, DATE_FORMAT1, handleErrorMessage } from "../../../../utils/Utils";
import ReactTable from "../../../system/ReactTable";
import useEffectOnce from "../../../system/useEffectOnce";
import ReviewPayment from "./ReviewPayment";

export default function ReviewPayments() {
  const { token, showMessage } = useAuth();
  const [reviewPayments, setReviewPayments] = useState([]);
  const [reviewPayment, setReviewPayment] = useState();

  const columns = [
    { title: "Ime i prezime", field: "userRequest" },
    { title: "Datum predaje", field: "submissionRequestTime" },
    { title: "Datum odobravanja", field: "submissionDoneTime" },
    { title: "Status", field: "status" },
    { title: "Ukupno", field: "total" },
  ];

  const [loading, setLoading] = useState(true);

  const reviewPaymentStatus = (status) => {
    switch (status) {
      case "IN_PROGRESS":
        return "U toku";
      case "ACCEPT":
        return "Prihvaćena";
      case "DECLINE":
        return "Odbijena";
      default:
        return "";
    }
  };

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${Endpoints.REVIEW_PAYMENTS}/my`, headers(token))
      .then(({ data }) => {
        const reviewPaymentArray = data.map((paymentReview) => ({
          id: paymentReview.id,
          userRequest: paymentReview.userRequest.fullName,
          submissionRequestTime: dateTimeView(paymentReview.submissionRequestTime).format(DATE_FORMAT1),
          total: paymentReview.paymentItemList.reduce((accumulator, { fee }) => {
            return accumulator + fee.amount;
          }, 0),
          status: reviewPaymentStatus(paymentReview.reviewPaymentType),
          paymentItemList: paymentReview.paymentItemList,
        }));
        setReviewPayments(reviewPaymentArray);
      })
      .catch((e) => handleErrorMessage(showMessage, e))
      .finally(() => setLoading(false));
  };
  useEffectOnce(() => {
    fetchData();
  });

  return (
    <>
      <ReactTable
        columns={columns}
        data={reviewPayments}
        loading={loading}
        reload={fetchData}
        opt={{
          title: "",
          item: "reviewPayments",
          filter: true,
          hideAdd: true,
          hideEdit: true,
          actions: [
            {
              icon: () => <PageviewIcon />,
              tooltip: "Pregled",
              onClick: (event, rowData) => setReviewPayment(rowData),
            },
          ],
        }}
      />
      <ReviewPayment
        reviewPayment={reviewPayment}
        close={() => setReviewPayment(undefined)}
        callbackAction={() => {
          fetchData();
          setReviewPayment(undefined);
        }}
      />
    </>
  );
}
