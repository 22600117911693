import i18n from "./i18n";

export const getString = (key, ...parameters) => {
  // i18n.changeLanguage("sr");
  return injectParams(i18n.t(key), parameters);
};

const injectParams = (str, params) => {
  let i = 0;
  let ret = str;
  params.forEach(p => {
    ret = ret.replace(`$${i}`, () => p);
    i++;
  });
  return ret;
};
