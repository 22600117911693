import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createMuiTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { dateTimeView } from "../../../utils/Utils";
import { localization, tableIcons } from "../../system/MaterialTableUtils";

export default function NewPlayerTeam({ teamID, setMembers, setOpen, fetchData }) {
  const { token } = useAuth();
  const [newMembers, setNewMembers] = useState([]);
  const [checkedMembers, setCheckedMembers] = useState([]);

  localization.body.emptyDataSourceMessage = "Nema igrača van tima";
  localization.toolbar.nRowsSelected = checkedMembers.length + (checkedMembers.length % 10 === 1 ? " izabran" : checkedMembers.length % 10 === 2 ? " izabrana" : " izabranih");

  const columns = [
    { title: "Igrač", field: "fullName" },
    { title: "Korisničko ime", field: "username" },
    { title: "Email", field: "email" },
    {
      title: "Datum rođenja",
      field: "birthDate",
      type: "date",
      render: (rowData) => (rowData.birthDate ? dateTimeView(rowData.birthDate).format("DD.MM.YYYY.") : ""),
    },
  ];

  useEffect(() => {
    axios
      .get(Endpoints.MEMBER + "/allFromClubNotInTeam/" + teamID, headers(token))
      .then((res) => {
        setNewMembers(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [teamID]);

  const saveNewPlayerTeam = () => {
    const addMembers = newMembers.filter((m) => {
      if (checkedMembers.includes(m.id)) {
        m.memberID = m.id ? m.id : null;
        m.teamID = teamID ? teamID : null;
        return m;
      }
    });
    if (addMembers.length === 0) {
      return;
    }
    axios
      .post(Endpoints.MEMBER_TEAM + "/addMembersToTeam", addMembers, headers(token))
      .then((result) => {
        setMembers((prev) => {
          addMembers.map((m) => {
            m.tableData.checked = false;
            prev.push(m);
          });
          return [...prev];
        });
        setOpen(false);
        fetchData();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#3f51b5",
      },
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: "#ffffff",
          background: "#3f51b5",
          "&:hover": {
            backgroundColor: "#303f9f",
          },
        },
      },
    },
  });

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"md"} open={true} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{newMembers.length > 0 ? "Dodavanje igrača koji su van tima" : ""}</DialogTitle>
        <DialogContent>
          <MuiThemeProvider theme={theme}>
            <MaterialTable
              icons={tableIcons}
              title=" Izaberite igrače"
              columns={columns}
              data={newMembers}
              options={{
                selection: true,
              }}
              onSelectionChange={(rows) => {
                const checkedArray = rows.map((p) => p.id);
                setCheckedMembers(checkedArray);
              }}
              localization={localization}
            />
          </MuiThemeProvider>
        </DialogContent>
        <DialogActions>
          {newMembers.length > 0 && (
            <MuiThemeProvider theme={theme}>
              <Button onClick={saveNewPlayerTeam} color="primary">
                Snimi
              </Button>
            </MuiThemeProvider>
          )}
          <Button color={newMembers.length > 0 ? "" : "primary"} onClick={() => setOpen(false)}>
            Odustani
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
