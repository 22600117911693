import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Endpoints from "../../utils/Endpoints";
import { localization, tableIcons } from "../system/MaterialTableUtils";
import DeleteDialog from "./DeleteDialog";

export default function ReactTable({ columns, data, loading, reload, opt }) {
  let history = useHistory();
  const { title, item, filter, hideActions, hideAdd, hideEdit, hideDelete, hideExport = false, actions, rowStyle, path } = opt;
  const [podaci, setPodaci] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletePutanja, setDeletePutanja] = useState();
  let link = item.indexOf("_") ? item.replace("_", "") : item;
  localization.body.emptyDataSourceMessage = loading ? "Učitavanje podataka" : "Nema podataka";

  const tableActions = [
    ...(actions ? actions : []),
    {
      icon: () => <AddBox />,
      tooltip: "Dodaj",
      isFreeAction: true,
      onClick: (event) => history.push(`/${link}`),
      hidden: hideAdd,
    },
    {
      icon: () => <Edit />,
      tooltip: "Izmeni",
      onClick: (event, rowData) => history.push(`/${link}/${rowData.id}`),
      hidden: hideEdit,
    },
    (rowData) => ({
      icon: () => <DeleteOutline />,
      tooltip: "Obriši",
      hidden: hideDelete,
      onClick: () => {
        setDeletePutanja(`${path ?? Endpoints[item.toUpperCase()]}/${rowData.id}`);
        setOpen(true);
      },
    }),
  ];

  useEffect(() => {
    setPodaci(data);
  }, [data]);

  return (
    <React.Fragment>
      <MaterialTable
        icons={tableIcons}
        title={title}
        isLoading={loading}
        columns={columns}
        data={podaci}
        localization={localization}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          filtering: filter,
          rowStyle: rowStyle,
          exportButton: !hideExport,
        }}
        actions={!hideActions && tableActions}
      />
      {open && <DeleteDialog deletePutanja={deletePutanja} setOpen={setOpen} setParent={setPodaci} objasnjenje={""} reload={reload} />}
    </React.Fragment>
  );
}
