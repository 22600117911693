import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import { getString } from "../../../lang/Local";
import Endpoints from "../../../utils/Endpoints";
import { fileToByteArray, handleErrorMessage } from "../../../utils/Utils";
import useEffectOnce from "../../system/useEffectOnce";
import useStyles from "./LegalDocumentStyle";

const fileTypes = [".pdf"];

export default function LegalDocument() {
  const { token, showMessage } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const classes = useStyles();
  const [legalDocument, setLegalDocument] = useState({ name: "", file: null });

  useEffectOnce(() => {
    isEdit && getLegalDocument();
  });

  const getLegalDocument = () => {
    axios
      .get(Endpoints.LEGAL_DOCUMENTS + "/" + id, headers(token))
      .then((res) => {
        setLegalDocument({
          ...res.data,
          file: null,
        });
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const save = async () => {
    const data = {
      id,
      name: legalDocument.name,
      file: legalDocument.file ? await fileToByteArray(legalDocument.file) : null,
      fileType: "pdf",
    };
    axios
      .post(Endpoints.LEGAL_DOCUMENTS, data, headers(token))
      .then(routeToMain)
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const routeToMain = () => history.push("/legalDocuments");
  const handleChange = (e) => setLegalDocument({ ...legalDocument, [e.target.name]: e.target.value });

  return (
    <div className={classes.root}>
      <Paper className={classes.section}>
        <ValidatorForm onSubmit={save}>
          <Grid container className={classes.main} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{id ? "Izmeni pravni dokument" : "Kreiraj pravni dokument"}</Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <TextField name="name" label="Naziv" value={legalDocument.name} onChange={handleChange} variant="outlined" margin="normal" required fullWidth />
              </Grid>
              <Grid item xs={12} md={12}>
                <DropzoneArea onChange={(files) => setLegalDocument({ ...legalDocument, file: files[0] })} name="file" acceptedFiles={fileTypes} />
              </Grid>
            </Grid>

            <Grid className={classes.buttons} item xs={12}>
              <Button variant="contained" onClick={routeToMain} className={classes.button}>
                {getString("common.cancel")}
              </Button>
              <Button variant="contained" color="primary" type="onSubmit" className={classes.button}>
                {getString("common.save")}
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Paper>
    </div>
  );
}
