import { Grid, IconButton } from "@material-ui/core";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import React from "react";
import { useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import Images from "../../../utils/Images";
import { handleErrorMessage } from "../../../utils/Utils";
import useStyles from "./UploadImageStyle";

export default function UploadImage({ object, setObject }) {
  const { showMessage } = useAuth();
  const classes = useStyles();

  const getImage = (source, replaceImage) => (source ? `data:image/${imageExtension(source)};base64,${source}` : replaceImage);

  const handleUpload = (e) => {
    readFileDataAsBase64(e.target.files[0])
      .then((response) => {
        const source = response.split("base64,")[1];
        const type = response.split("data:image/")[1].split(";base64")[0];
        setObject({ ...object, picture: source, pictureType: type });
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  function readFileDataAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  }

  const imageExtension = (base64) => {
    switch (base64.charAt(0)) {
      case "/":
        return "jpeg";
      case "i":
        return "png";
      case "R":
        return "gif";
      case "U":
        return "webp";
      default:
        return alert("Unknown extension");
    }
  };

  const imageUrl = () => {
    if (!object?.picture && !object.image) {
      return Images.NO_PICTURE;
    }
    return object?.picture ? getImage(object.picture, Images.NO_PICTURE) : Endpoints.FILES + "/image/" + object.image?.id;
  };

  return (
    <Grid row xs={2} justify="flex-start" alignItems="flex-start">
      <div className={classes.container}>
        <img src={imageUrl()} className={classes.image} alt="Logo" />
        <div className={classes.overlay}>
          <label htmlFor="input-file">
            <IconButton className={classes.upload} color="colorSecondary" aria-label="upload picture" component="span">
              <AddAPhotoIcon />
            </IconButton>
            <input accept="image/*" onChange={handleUpload} id="input-file" type="file" hidden />
          </label>
        </div>
      </div>
    </Grid>
  );
}
