import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { MUIEditorState } from "react-mui-draft-wysiwyg";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { handleErrorMessage, richTextStateFromHTML, richTextStateToHtml, richTextValue } from "../../../utils/Utils";
import RichText from "../../system/rich-text/RichText";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./ExerciseStyle";
import MultiSelectStyled from "./MultiSelectStyled";

const localization = {
  selectSomeItems: "Tip *",
  allItemsAreSelected: "Svi tipovi",
  selectAll: "Sve",
  search: "Pretraga",
};

export default function Exercise() {
  const { token, showMessage } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const classes = useStyles();
  const textRef = useRef();
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [exercise, setExercise] = useState({ name: "", exerciseType: [], description: MUIEditorState.createEmpty() });

  useEffectOnce(() => {
    isEdit && getExercise();
    getExerciseTypes();
  });

  const getExercise = () => {
    axios
      .get(Endpoints.EXERCISE + "/" + id, headers(token))
      .then((res) => {
        const { id, name, exerciseTypeExerciseList } = res.data;
        const parseData = { id, name, exerciseType: parseExerciseType(exerciseTypeExerciseList.map(({ exerciseType }) => exerciseType)) };
        setExercise(parseData);
        textRef.current.set(richTextStateFromHTML(res.data.description));
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const getExerciseTypes = () => {
    axios
      .get(Endpoints.EXERCISE_TYPE, headers(token))
      .then((res) => {
        setExerciseTypes(parseExerciseType(res.data));
      })
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const save = () => {
    if (exercise.exerciseType.length === 0) return showMessage("Tip je obavezno polje", MessageType.ERROR);

    const exerciseBody = {
      id: exercise.id,
      name: exercise.name,
      exerciseTypesId: exercise.exerciseType.map(({ value }) => value),
      description: richTextStateToHtml(richTextValue(textRef)),
    };
    axios
      .post(Endpoints.EXERCISE, exerciseBody, headers(token))
      .then(routeToMain)
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const routeToMain = () => history.push("/exercises");
  const handleChange = (e) => setExercise({ ...exercise, [e.target.name]: e.target.value });
  const handleMultiSelectChange = (array) => setExercise({ ...exercise, exerciseType: array });
  const parseExerciseType = (data) => data.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Paper className={classes.section}>
      <ValidatorForm onSubmit={save}>
        <Grid container className={classes.main} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{id ? "Izmeni vežbu" : "Kreiraj vežbu"}</Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField name="name" label="Naziv" value={exercise.name} onChange={handleChange} variant="outlined" margin="normal" required fullWidth />
            </Grid>
            <Grid item xs={12} md={6} style={{ alignSelf: "center" }}>
              <MultiSelectStyled
                name="exerciseType"
                options={exerciseTypes}
                value={exercise.exerciseType}
                onChange={handleMultiSelectChange}
                required
                overrideStrings={localization}
                className={classes.multiselect}
              />
            </Grid>
            <Grid item xs={12}>
              <RichText muiEditorRef={textRef} />
            </Grid>
          </Grid>

          <Grid className={classes.buttons} item xs={12}>
            <Button variant="contained" onClick={routeToMain} className={classes.button}>
              {getString("common.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="onSubmit" className={classes.button}>
              {getString("common.save")}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Paper>
  );
}
