import { Card, CardContent, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { handleErrorMessage } from "../../../utils/Utils";
import useEffectOnce from "../../system/useEffectOnce";
import useStyles from "./UserStyle";

export default function UserGroups() {
  const { token, showMessage } = useAuth();
  let { id } = useParams();
  const classes = useStyles();
  const [userGroup, setUserGroup] = useState([]);
  const bull = <span className={classes.bullet}>•</span>;

  useEffectOnce(() => {
    if (id) {
      axios
        .get(Endpoints.TEAM + "/byUser/" + id, headers(token))
        .then((response) => {
          setUserGroup(response.data);
        })
        .catch((e) => {
          handleErrorMessage(showMessage, e);
        });
    }
  });

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Spisak grupa:
        </Typography>
        {userGroup?.length === 0 && <Typography variant="body1">{bull} Nema grupa</Typography>}
        {userGroup?.map((team) => {
          const ids = parseInt(id);
          const role = team.headCoach.id === ids ? "Glavni trener" : team.assistCoach.id === ids ? "Pomoćni trener" : "";
          return (
            <Typography variant="body1">
              {bull} {team.name} - {role}
            </Typography>
          );
        })}
      </CardContent>
    </Card>
  );
}
