import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { handleErrorMessage } from "../../../utils/Utils";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./LocationStyle";

export default function LocationInfo() {
  const { token, showMessage } = useAuth();
  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [location, setLocation] = useState({
    name: "",
    addressNumber: "",
    city: "",
    mailNumber: "",
    director: "",
    secretar: "",
    janitor: "",
    cleaningLady: "",
    directorPhone: "",
    secretarPhone: "",
    janitorPhone: "",
    cleaningLadyPhone: "",
    directorEmail: "",
    secretarEmail: "",
    janitorEmail: "",
    cleaningLadyEmail: "",
    note: "",
    longitude: "",
    latitude: "",
  });

  useEffectOnce(() => fetchData());

  const fetchData = () => {
    if (id) {
      axios
        .get(`${Endpoints.LOCATION}/${id}`, headers(token))
        .then((result) => setLocation(result.data))
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  };

  const save = () => {
    axios
      .post(Endpoints.LOCATION, location, headers(token))
      .then((res) => {
        showMessage("Uspešno snimljeno", MessageType.SUCCESS);
        history.push("/locations");
      })
      .catch((e) => console.error(e));
  };

  const handleChange = (e) => setLocation({ ...location, [e.target.name]: e.target.value });

  return (
    <React.Fragment>
      <ValidatorForm>
        <Paper className={classes.paper}>
          <Grid spacing={2} container>
            <Typography variant="h5">{id ? "Lokacija" : "Nova lokacija"}</Typography>
          </Grid>
          <Grid container>
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="name" name="name" label="Naziv" value={location.name} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="addressNumber" name="addressNumber" label="Adresa" value={location.addressNumber} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="city" name="city" label="Grad" value={location.city} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="mailNumber" name="mailNumber" label="Poštanski broj" value={location.mailNumber} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            {/* Kontakt ime */}
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="director" name="director" label="Direktor" value={location.director} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="secretar" name="secretar" label="Sekretar" value={location.secretar} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="janitor" name="janitor" label="Domar" value={location.janitor} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="cleaningLady" name="cleaningLady" label="Tetkica" value={location.cleaningLady} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            {/* Kontakt telefon */}
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="directorPhone" name="directorPhone" label="Direktor telefon" value={location.directorPhone} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="secretarPhone" name="secretarPhone" label="Sekretar telefon" value={location.secretarPhone} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="janitorPhone" name="janitorPhone" label="Domar telefon" value={location.janitorPhone} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="cleaningLadyPhone"
                  name="cleaningLadyPhone"
                  label="Tetkica telefon"
                  value={location.cleaningLadyPhone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* Kontakt email */}
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  id="directorEmail"
                  name="directorEmail"
                  label="Direktor email"
                  value={location.directorEmail}
                  onChange={handleChange}
                  fullWidth
                  validators={["isEmail"]}
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>
              <Grid item xs={3}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  id="secretarEmail"
                  name="secretarEmail"
                  label="Sekretar email"
                  value={location.secretarEmail}
                  onChange={handleChange}
                  fullWidth
                  validators={["isEmail"]}
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>
              <Grid item xs={3}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  id="janitorEmail"
                  name="janitorEmail"
                  label="Domar email"
                  value={location.janitorEmail}
                  onChange={handleChange}
                  fullWidth
                  validators={["isEmail"]}
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>
              <Grid item xs={3}>
                <TextValidator
                  variant="outlined"
                  margin="normal"
                  id="cleaningLadyEmail"
                  name="cleaningLadyEmail"
                  label="Tetkica email"
                  value={location.cleaningLadyEmail}
                  onChange={handleChange}
                  fullWidth
                  validators={["isEmail"]}
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>
            </Grid>
            {/* napomena pozicija */}
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <TextField variant="outlined" margin="normal" id="note" name="note" label="Napomena" value={location.note} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="latitude" name="latitude" label="Geo. širina" value={location.latitude} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="longitude" name="longitude" label="Geo. dužina" value={location.longitude} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            <Grid className={classes.padding_2} container spacing={1} justify="flex-end">
              <Grid item>
                <Button variant="contained" onClick={(e) => history.push("/locations")}>
                  {getString("common.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={save}>
                  {getString("common.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </ValidatorForm>
    </React.Fragment>
  );
}
