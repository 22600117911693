import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import React, { useState } from "react";
import { useParams } from "react-router";
import useStyles from "./RoleStyle";
import RoleInfo from "./tabs/info/RoleInfo";
import RolePermissions from "./tabs/permission/RolePermission";

const TAB_INFO = 0;
const TAB_PERMISSION = 1;

export default function Role() {
  const classes = useStyles();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TAB_INFO);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="icon tabs example"
          >
            <Tab label="Uloga" icon={<InfoIcon />} />
            <Tab
              label="Aplikativna prava"
              icon={<PeopleIcon />}
              disabled={!id}
            />
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_INFO && <RoleInfo />}
      {selectedTab === TAB_PERMISSION && <RolePermissions />}
    </div>
  );
}
