import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { handleErrorMessage } from "../../../utils/Utils";
import { getString } from "./../../../lang/Local";
import useStyles from "./TeamStyle";

export default function TeamInfo({ setSelectedTab }) {
  const { token, showMessage } = useAuth();

  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [team, setTeam] = useState({
    name: "",
    headCoach: { title: "" },
    assistCoach: { title: "" },
    note: "",
    numericalValue: "",
  });

  useEffect(() => {
    if (id) {
      axios
        .get(Endpoints.TEAM + "/" + id, headers(token))
        .then((result) => {
          const teamRes = result.data;
          const teamOld = {
            ...teamRes,
            headCoach: {
              id: teamRes.headCoach?.id,
              title: teamRes.headCoach?.fullName,
            },
            assistCoach: {
              id: teamRes.assistCoach?.id,
              title: teamRes.assistCoach?.fullName,
            },
          };
          setTeam(teamOld);
        })
        .catch((e) => {
          handleErrorMessage(showMessage, e);
        });
    }

    axios
      .get(Endpoints.USER, headers(token))
      .then((result) => {
        let userArray = result.data.map((user) => {
          return { id: user.id, title: user.fullName };
        });
        setUsers(userArray);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  }, [id]);

  const save = () => {
    if (!team.headCoach?.id) {
      showMessage("Glavni trener je obavezno polje", MessageType.ERROR);
    }
    let teamNew = team;
    teamNew.headCoachID = team.headCoach.id ? team.headCoach.id : null;
    teamNew.assistCoachID = team.assistCoach.id ? team.assistCoach.id : null;
    axios
      .post(Endpoints.TEAM, teamNew, headers(token))
      .then((result) => {
        history.push("/team/" + result.data.id);
        setSelectedTab((prev) => prev + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChange = (e) => {
    setTeam({ ...team, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <Paper className={classes.paperTab}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextField variant="outlined" margin="normal" id="name" name="name" label="Naziv" value={team.name} onChange={handleChange} required fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="combo-box-demo"
                options={users}
                getOptionLabel={(option) => option?.title}
                value={team.headCoach}
                onChange={(event, value) =>
                  setTeam({
                    ...team,
                    headCoach: value ? value : {},
                  })
                }
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="headCoach" name="headCoach" label="Glavni trener" required fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="combo-box-demo"
                options={users}
                getOptionLabel={(option) => option.title}
                value={team.assistCoach}
                onChange={(event, value) =>
                  setTeam({
                    ...team,
                    assistCoach: value ? value : {},
                  })
                }
                renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" id="assistCoach" name="assistCoach" label="Pomoćni trener" fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <TextField variant="outlined" margin="normal" id="note" name="note" label="Napomena" value={team.note} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField variant="outlined" margin="normal" id="numericalValue" name="numericalValue" label="Red br." value={team.numericalValue} onChange={handleChange} fullWidth />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button variant="contained" onClick={(e) => history.goBack()} className={classes.button}>
              {getString("common.cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={save} className={classes.button}>
              {getString("common.save")}
            </Button>
          </div>
        </Paper>
      </main>
    </React.Fragment>
  );
}
