import MultiSelect from "react-multi-select-component";
import styled from "styled-components";

const MultiSelectStyled = styled(MultiSelect)`
  .dropdown-container {
    padding: 8px;
  }
`;

export default MultiSelectStyled;
