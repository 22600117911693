import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { handleErrorMessage } from "../../../utils/Utils";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./LocationStyle";

export default function LocationPrice() {
  const { token, showMessage } = useAuth();
  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();
  const [locationPrice, setLocationPrice] = useState(["", "", "", "", "", "", ""]);

  useEffectOnce(() => fetchData());

  const fetchData = () => {
    if (id) {
      axios
        .get(Endpoints.LOCATION_PRICE + "/location/" + id, headers(token))
        .then((result) => {
          const arrayList = [];
          result.data.forEach((lp) => {
            arrayList[lp.dayInWeek - 1] = lp.price;
          });
          setLocationPrice(arrayList);
        })
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  };

  const save = () => {
    const newArray = locationPrice.map((price, index) => {
      return { locationID: id, dayInWeek: index + 1, price: parseInt(price) };
    });
    axios
      .post(Endpoints.LOCATION_PRICE, newArray, headers(token))
      .then((res) => showMessage("Uspešno snimljeno", MessageType.SUCCESS))
      .catch((e) => console.error(e));
  };

  const handleChange = (e) => {
    setLocationPrice((prev) => {
      const newValue = [...prev];
      newValue[parseInt(e.target.name)] = e.target.value;
      return newValue;
    });
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography variant="h5">Cene po danima</Typography>
        <Grid spacing={1} container direction="row" justify="center" alignItems="center">
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              name="0"
              label="Ponedeljak"
              value={locationPrice[0]}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              name="1"
              label="Utorak"
              value={locationPrice[1]}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              name="2"
              label="Sreda"
              value={locationPrice[2]}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              name="3"
              label="Četvrtak"
              value={locationPrice[3]}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              variant="outlined"
              margin="normal"
              name="4"
              label="Petak"
              value={locationPrice[4]}
              onChange={handleChange}
              required
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
          </Grid>
        </Grid>
        <Grid spacing={2} container direction="row" justify="space-around" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <Grid spacing={1} container direction="row" justify="center" alignItems="center">
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="5"
                  label="Subota"
                  value={locationPrice[5]}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  name="6"
                  label="Nedelja"
                  value={locationPrice[6]}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid className={classes.padding_2} container spacing={1} justify="flex-end">
              <Grid item>
                <Button variant="contained" onClick={(e) => history.push("/members")} className={classes.button}>
                  {getString("common.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={save} className={classes.button}>
                  {getString("common.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
function NumberFormatCustom({ inputRef, onChange, name, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}
