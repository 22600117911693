import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./context/auth";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { token } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <div>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}
