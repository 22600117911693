import CssBaseline from "@material-ui/core/CssBaseline";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import { dateTimeView, handleErrorMessage } from "../../../utils/Utils";
import DeleteDialog from "../../system/DeleteDialog";
import { localization, tableIcons } from "../../system/MaterialTableUtils";
import NewPlayerTeam from "./NewPlayerTeam";

export default function TeamMember() {
  const { token, showMessage } = useAuth();
  const { id } = useParams();
  const [members, setMembers] = useState([]);
  const [openNew, setOpenNew] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deletePutanja, setDeletePutanja] = useState();
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const columns = [
    { title: "Ime", field: "firstName" },
    { title: "Prezime", field: "lastName" },
    { title: "Korisničko ime", field: "username" },
    { title: "Email", field: "email" },
    {
      title: "Datum rođenja",
      field: "birthDate",
      type: "date",
    },
  ];

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () =>
    axios
      .get(`${Endpoints.TEAM}/${id}/members`, headers(token))
      .then((result) => {
        const membersArray = result.data.map((m) => ({
          id: m.id,
          firstName: m.firstName,
          lastName: m.lastName,
          username: m.username,
          email: m.email,
          birthDate: m.birthDate ? dateTimeView(m.birthDate).format("DD.MM.YYYY.") : "",
        }));
        setMembers(membersArray);
        setLoading(false);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <MaterialTable
          icons={tableIcons}
          title="Pregled igrača"
          columns={columns}
          data={members}
          localization={localization}
          loading={loading}
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            exportButton: {
              csv: true,
            },
            exportAllData: true,
            exportFileName: "Pregled igraca",
          }}
          actions={[
            {
              icon: () => <AddBox />,
              tooltip: "Dodaj",
              isFreeAction: true,
              onClick: () => {
                setOpenNew(true);
              },
            },
            (rowData) => ({
              icon: () => <Edit />,
              tooltip: "Izmeni",
              onClick: () => history.push(`/member/${rowData.id}`),
            }),

            (rowData) => ({
              icon: () => <DeleteOutline />,
              tooltip: "Obriši",
              onClick: () => {
                setDeletePutanja(`${Endpoints.MEMBER_TEAM}/member/${rowData.id}/team/${id}`);
                setOpenDelete(true);
                fetchData();
              },
            }),
          ]}
        />
        {openNew && <NewPlayerTeam teamID={id} setMembers={setMembers} setOpen={setOpenNew} fetchData={fetchData} />}
        {openDelete && <DeleteDialog deletePutanja={deletePutanja} setOpen={setOpenDelete} setParent={setMembers} objasnjenje={""} />}
      </main>
    </React.Fragment>
  );
}
