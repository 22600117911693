import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import React from "react";
import { headers, useAuth } from "../../context/auth";
import MessageType from "../../utils/MessageType";

export default function DeleteDialog({ deletePutanja, setOpen, setParent, objasnjenje, reload }) {
  const { token, showMessage } = useAuth();

  const deleteItem = () => {
    setOpen(false);
    if (!deletePutanja) {
      showMessage("Putanja za brisanje nije definisana", MessageType.ERROR);
      return;
    }
    axios
      .delete(deletePutanja, headers(token))
      .then((res) => {
        if (reload) {
          reload();
        } else {
          setParent((parent) => {
            const delObj = parent.find((obj) => obj.id === res.data);
            if (delObj) {
              parent.splice(parent.indexOf(delObj), 1);
            }

            return [...parent];
          });
        }
        showMessage("Uspešno obrisano", MessageType.SUCCESS);
      })
      .catch((e) => showMessage("Greška prilikom brisanja", MessageType.ERROR));
  };

  return (
    <Dialog open={true} onClose={() => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Da li ste sigurni da želite da obrišete?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{objasnjenje}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteItem} color="primary" autoFocus>
          Da
        </Button>
        <Button onClick={() => setOpen(false)} color="primary">
          Ne
        </Button>
      </DialogActions>
    </Dialog>
  );
}
