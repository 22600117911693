import { Grid } from "@material-ui/core";
import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Club from "./components/admin/club/Club";
import Clubs from "./components/admin/club/Clubs";
import Events from "./components/admin/event/Events";
import ExercisesType from "./components/admin/exercise-type/ExercisesType";
import ExerciseType from "./components/admin/exercise-type/ExerciseType";
import Exercise from "./components/admin/exercise/Exercise";
import Exercises from "./components/admin/exercise/Exercises";
import Finance from "./components/admin/finance/Finance";
import Header from "./components/admin/header/Header";
import LegalDocument from "./components/admin/legal-document/LegalDocument";
import LegalDocuments from "./components/admin/legal-document/LegalDocuments";
import Location from "./components/admin/location/Location";
import Locations from "./components/admin/location/Locations";
import Member from "./components/admin/member/Member";
import Members from "./components/admin/member/Members";
import Post from "./components/admin/posts/Post";
import { Posts } from "./components/admin/posts/Posts";
import Profile from "./components/admin/profile/Profile";
import Reports from "./components/admin/reports/Reports";
import Role from "./components/admin/role/Role";
import Roles from "./components/admin/role/Roles";
import Team from "./components/admin/team/Team";
import Teams from "./components/admin/team/Teams";
import User from "./components/admin/user/User";
import Users from "./components/admin/user/Users";
import Login from "./components/Login";
import Sidemenu from "./components/sidemenu/Sidemenu";
import { useAuth } from "./context/auth";
import PrivateRoute from "./PrivateRoute";

export default function Routers() {
  const { token } = useAuth();
  return (
    <Router>
      {token && <Header />}
      <div
        style={{
          display: "flex",
          height: "94.3vh",
          marginTop: "5.7svh",
          backgroundColor: "#eef2f6",
        }}
      >
        {token && <Sidemenu />}
        <Grid xs={12} style={{ padding: "24px" }}>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Events} />
          <PrivateRoute exact path="/club/:id?" component={Club} />
          <PrivateRoute exact path="/clubs" component={Clubs} />
          <PrivateRoute exact path="/events" component={Events} />
          <PrivateRoute exact path="/role/:id?" component={Role} />
          <PrivateRoute exact path="/roles" component={Roles} />
          <PrivateRoute exact path="/team/:id?" component={Team} />
          <PrivateRoute exact path="/teams" component={Teams} />
          <PrivateRoute exact path="/user/:id?" component={User} />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/member/:id?" component={Member} />
          <PrivateRoute exact path="/members" component={Members} />
          <PrivateRoute exact path="/location/:id?" component={Location} />
          <PrivateRoute exact path="/locations" component={Locations} />
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/finance" component={Finance} />
          <PrivateRoute exact path="/posts" component={Posts} />
          <PrivateRoute exact path="/post/:id?" component={Post} />
          <PrivateRoute exact path="/exercises" component={Exercises} />
          <PrivateRoute exact path="/exercise/:id?" component={Exercise} />
          <PrivateRoute exact path="/exercisesType" component={ExercisesType} />
          <PrivateRoute exact path="/exerciseType/:id?" component={ExerciseType} />
          <PrivateRoute exact path="/legalDocuments" component={LegalDocuments} />
          <PrivateRoute exact path="/legalDocument/:id?" component={LegalDocument} />
        </Grid>
      </div>
    </Router>
  );
}
