import MomentUtils from "@date-io/moment";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import NumberFormat from "react-number-format";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { dateTimeView, DATE_FORMAT3, handleErrorMessage } from "../../../utils/Utils";
import UploadImage from "../../system/upload-image/UploadImage";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./MemberStyle";

export default function Member() {
  const { token, showMessage } = useAuth();
  let history = useHistory();
  let { id } = useParams();
  const classes = useStyles();

  const [teams, setTeams] = useState([]);
  // const [statusMember, setStatusMember] = useState([]);
  const [member, setMember] = useState({
    firstName: "",
    lastName: "",
    birthDate: null,
    gender: "",
    email: "",
    addressNumber: "",
    city: "",
    zipCode: "",
    username: "",
    password: "",
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    newPassword: "",
    confirmPassword: "",
    placeOfBirth: "",
    licenceNumber: "",
    motherFirstName: "",
    motherLastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    contactPlayerMam: "",
    contactPlayerDad: "",
    mainContactPhone: "",
    mainPlayerPhone: "",
    dateJoiningTeam: null,
    dateLeavingTeam: null,
    height: "",
    weight: "",
    statusMember: { id: "", name: "" },
    medicalTest: null,
    teams: [],
    picture: "",
    jmbg: "",
  });
  const [showDialogChangePassword, setShowDialogChangePassword] = useState(false);

  const changePassword = () => {
    if (!member.newPassword) {
      showMessage("Polje nova lozinka je obavezno.", MessageType.ERROR);
      return;
    } else if (!member.confirmPassword) {
      showMessage("Polje potvrdna lozinka je obavezno.", MessageType.ERROR);
      return;
    } else if (member.confirmPassword !== member.newPassword) {
      showMessage("Potvrdna lozinka mora biti ista kao i nova lozinka.", MessageType.ERROR);
      return;
    }
    const data = {
      newPassword: member.newPassword,
      confirmPassword: member.confirmPassword,
    };
    axios
      .post(`${Endpoints.MEMBER}/${id}/changePassword`, data, headers(token))
      .then((result) => {
        showMessage("Uspešno izmenjena lozinka.", MessageType.SUCCESS);
        setShowDialogChangePassword(false);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  const fetchMemberInfo = () => {
    axios
      .get(Endpoints.MEMBER + "/info/" + (id ?? ""), headers(token))
      .then((response) => {
        const { memberInfo, memberTeams, allTeams } = response.data;
        if (memberInfo) {
          // memberInfo.statusMember = memberInfo.memberStatus;
          let memberParse = { ...memberInfo };
          memberParse.teams = memberTeams;
          memberParse.birthDate = memberParse.birthDate ? dateTimeView(memberParse.birthDate) : null;
          memberParse.medicalTest = memberParse.medicalTest ? dateTimeView(memberParse.medicalTest) : null;

          setMember(memberParse);
        }
        setTeams(allTeams);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  useEffectOnce(() => {
    fetchMemberInfo();
  });

  const validation = () => {
    if (!member.firstName) {
      showMessage("Ime je obavezno polje.", MessageType.ERROR);
      return false;
    } else if (!member.lastName) {
      showMessage("Prezime je obavezno polje.", MessageType.ERROR);
      return false;
    } else if (!member.gender) {
      showMessage("Pol je obavezno polje.", MessageType.ERROR);
      return false;
    } else if (!member.username) {
      showMessage("Korisničko ime je obavezno polje.", MessageType.ERROR);
      return false;
    }

    return true;
  };

  const onDateChange = (value, name) => setMember({ ...member, [name]: value });

  const save = () => {
    let newUser = { ...member };
    newUser.birthDate = newUser.birthDate ? moment(newUser.birthDate).format(DATE_FORMAT3) : null;
    newUser.medicalTest = newUser.medicalTest ? moment(newUser.medicalTest).format(DATE_FORMAT3) : null;
    // newUser.statusMemberID = newUser.statusMember.id;    newUser.teamsID = newUser.teams.map((g) => g.id);
    newUser.teamsID = newUser.teams.map((g) => g.id);
    if (validation()) {
      axios
        .post(Endpoints.MEMBER, newUser, headers(token))
        .then((result) => {
          showMessage("Uspešno snimljeno", MessageType.SUCCESS);
          history.goBack();
        })
        .catch((e) => {
          handleErrorMessage(showMessage, e);
        });
    }
  };

  const handleChange = (e) => setMember({ ...member, [e.target.name]: e.target.value });

  const openDialogResetPassword = () => {
    setShowDialogChangePassword(true);
    setMember({ ...member, newPassword: "", confirmPassword: "" });
  };

  const closeDialogChangePasswrod = () => setShowDialogChangePassword(false);

  return (
    <React.Fragment>
      <ValidatorForm>
        <Paper className={classes.paper}>
          <Grid spacing={2} container>
            <Typography variant="h5">{id ? "Član" : "Novi član"}</Typography>
          </Grid>
          <Grid container>
            <UploadImage object={member} setObject={setMember} />
            <Grid row xs={10}>
              <Grid spacing={2} container>
                <Grid item xs={2}>
                  <TextField variant="outlined" margin="normal" id="firstName" name="firstName" label="Ime" value={member.firstName} onChange={handleChange} required fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField variant="outlined" margin="normal" id="lastName" name="lastName" label="Prezime" value={member.lastName} onChange={handleChange} required fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      variant="inline"
                      inputVariant="outlined"
                      disableFuture
                      autoOk
                      clearLabel={false}
                      openTo="year"
                      margin="normal"
                      label="Datum rođenja"
                      format={"DD.MM.YYYY."}
                      value={member.birthDate}
                      InputAdornmentProps={{ position: "start", variant: "standard" }}
                      onChange={(date) => onDateChange(date, "birthDate")}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    id="email"
                    name="email"
                    label="Email"
                    value={member.email}
                    onChange={handleChange}
                    fullWidth
                    validators={["isEmail"]}
                    errorMessages={["Email je neispravan"]}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormLabel component="legend">Pol *</FormLabel>
                  <RadioGroup
                    name="gender"
                    onChange={(event, value) => {
                      setMember({ ...member, gender: value });
                    }}
                    required
                    aria-label="position"
                    row
                  >
                    <FormControlLabel value={"MALE"} checked={member.gender === "MALE"} control={<Radio color="primary" />} label="Muški" />
                    <FormControlLabel value={"FEMALE"} checked={member.gender === "FEMALE"} control={<Radio color="primary" />} label="Ženski" />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid spacing={2} container>
                <Grid item xs={3}>
                  <TextField variant="outlined" margin="normal" id="addressNumber" name="addressNumber" label="Adresa i broj" value={member.addressNumber} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={2}>
                  <TextField variant="outlined" margin="normal" id="city" name="city" label="Grad" value={member.city} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={3}>
                  <TextField variant="outlined" margin="normal" id="zipCode" name="zipCode" label="Poštanski broj" value={member.zipCode} onChange={handleChange} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField variant="outlined" margin="normal" id="placeOfBirth" name="placeOfBirth" label="Mesto rođenja" value={member.placeOfBirth} onChange={handleChange} fullWidth />
                </Grid>
              </Grid>
            </Grid>
            {/* roditelji info */}
            <Grid spacing={2} container>
              <Grid item xs={4}>
                <TextField variant="outlined" margin="normal" id="jmbg" name="jmbg" label="JMBG" value={member.jmbg} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="height"
                  name="height"
                  label="Težina"
                  value={member.height}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="weight"
                  name="weight"
                  label="Visina"
                  value={member.weight}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="start">cm</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="motherFirstName" name="motherFirstName" label="Ime majke" value={member.motherFirstName} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="motherLastName" name="motherLastName" label="Prezime majke" value={member.motherLastName} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="fatherFirstName" name="fatherFirstName" label="Ime oca" value={member.fatherFirstName} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="fatherLastName" name="fatherLastName" label="Prezime oca" value={member.fatherLastName} onChange={handleChange} required fullWidth />
              </Grid>
            </Grid>
            {/* igrac kontakt */}
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="mainContactPhone" name="mainContactPhone" label="Glavni telefon" value={member.mainContactPhone} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="contactPlayerPhone"
                  name="contactPlayerPhone"
                  label="Telefon igrača"
                  value={member.contactPlayerPhone}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="contactPlayerMom" name="contactPlayerMom" label="Telefon majke" value={member.contactPlayerMom} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="contactPlayerDad" name="contactPlayerDad" label="Telefon oca" value={member.contactPlayerDad} onChange={handleChange} fullWidth />
              </Grid>
            </Grid>
            {/* poslednji red */}
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  options={teams}
                  value={member.teams}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => {
                    setMember({ ...member, teams: value });
                  }}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox color="primary" icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} checked={selected} />
                      {option.name}
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Grupe" fullWidth />}
                />
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    disableFuture
                    autoOk
                    clearLabel={false}
                    openTo="year"
                    margin="normal"
                    label="Lekarski pregled"
                    format={"DD.MM.YYYY."}
                    value={member.medicalTest}
                    onChange={(date) => onDateChange(date, "medicalTest")}
                    InputAdornmentProps={{ position: "start", variant: "standard" }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                <TextField variant="outlined" margin="normal" id="licenceNumber" name="licenceNumber" label="Broj licence" value={member.licenceNumber} onChange={handleChange} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField variant="outlined" margin="normal" id="username" name="username" label="Username" value={member.username} onChange={handleChange} required fullWidth />
              </Grid>
              {!id && (
                <Grid item xs={4}>
                  <TextField fullWidth variant="outlined" margin="normal" id="password" name="password" label="Password" value={member.password} onChange={handleChange} required />
                </Grid>
              )}
            </Grid>
            <Grid className={classes.padding_2} container spacing={1} justify="flex-end">
              <Grid item>
                <Button variant="contained" onClick={(e) => history.goBack()} className={classes.button}>
                  {getString("common.cancel")}
                </Button>
              </Grid>

              {id && (
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={openDialogResetPassword} className={classes.button}>
                    Promena lozinke
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Button variant="contained" color="primary" onClick={save} className={classes.button}>
                  {getString("common.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </ValidatorForm>
      <Dialog open={showDialogChangePassword} onClose={closeDialogChangePasswrod} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Promena lozinke</DialogTitle>
        <DialogContent>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel>Nova lozinka</InputLabel>
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              type={member.showNewPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setMember({ ...member, showNewPassword: !member.showNewPassword })}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {member.showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel>Potvrda lozinke</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              name="confirmPassword"
              type={member.showConfirmPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setMember({ ...member, showConfirmPassword: !member.showConfirmPassword });
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {member.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={110}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogChangePasswrod}>Odustani</Button>
          <Button onClick={changePassword} color="primary" variant="contained">
            Snimi
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function NumberFormatCustom({ inputRef, onChange, name, ...other }) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}
