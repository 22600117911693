import React, { useEffect, useState } from "react";

import ReactTable from "../../system/ReactTable";
import axios from "axios";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";

export default function Roles() {
  const { token } = useAuth();
  const [roles, setRoles] = useState();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(Endpoints.ROLE, headers(token))
      .then((response) => {
        const rolesArray = response.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
            nameShort: role.nameShort,
            code: role.code,
            orderNumber: role.orderNumber,
          };
        });
        setRoles(rolesArray);
        setLoading(false);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    { title: "Naziv", field: "name" },
    { title: "Skraćeni naziv", field: "nameShort" },
    { title: "Šifra", field: "code" },
    { title: "Redosled prikazivanja", field: "orderNumber" },
  ];

  return (
    <ReactTable
      columns={columns}
      data={roles}
      loading={loading}
      reload={fetchData}
      opt={{ title: "Uloge", item: "role", filter: false }}
    />
  );
}
