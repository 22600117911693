const getUrl = () => {
  let backendAddress;
  let backendPort = 8080;
  if (process.env.NODE_ENV === "production") {
    backendAddress = `https://backend.evico-app.com`;
  } else {
    backendAddress = `http://localhost:${backendPort}`;
  }
  return backendAddress;
};
const Endpoints = {
  AUTHENTICATE: getUrl() + "/authenticate",
  BLOCK: getUrl() + "/api/blocks",
  BLOCK_TYPE: getUrl() + "/api/blockTypes",
  CODE: getUrl() + "/api/codes",
  CLUB: getUrl() + "/api/clubs",
  COMPETITION: getUrl() + "/api/competitions",
  COMPETITION_GROUP: getUrl() + "/api/competitionGroups",
  COMPETITION_GROUP_TEAM: getUrl() + "/api/competitionGroupTeams",
  COMPETITION_PLAY: getUrl() + "/api/competitionPlay",
  COMPETITION_TEAM: getUrl() + "/api/competitionTeams",
  COMPETITION_TYPE: getUrl() + "/api/competitionTypes",
  EVENT: getUrl() + "/api/events",
  ESTIMATIONS: getUrl() + "/api/estimations",
  ESTIMATIONS_TEAM: getUrl() + "/api/estimations/team",
  FEE: getUrl() + "/api/fees",
  FOUL: getUrl() + "/api/fouls",
  FOUL_TYPE: getUrl() + "/api/foulTypes",
  GAME: getUrl() + "/api/games",
  GAME_PERIOD: getUrl() + "/api/gamePeriods",
  GAME_PERIOD_END: getUrl() + "/api/gamePeriods/endGamePeriod",
  LOCATION: getUrl() + "/api/locations",
  LOCATION_PRICE: getUrl() + "/api/locationPrice",
  MEMBER: getUrl() + "/api/members",
  MEMBER_TEAM: getUrl() + "/api/membersTeam",
  PLAYGROUND: getUrl() + "/api/playgrounds",
  PLAYGROUND_BY_GAME_PERIOD: getUrl() + "/api/playgrounds/byGamePeriod",
  PLAYER: getUrl() + "/api/players",
  PLAYER_ADD_ON_COURT: getUrl() + "/api/players/addPlayerOnCourt",
  PLAYER_GAME: getUrl() + "/api/playerGames",
  PLAYER_TEAM: getUrl() + "/api/playerTeams",
  POINT: getUrl() + "/api/points",
  POINT_TYPE: getUrl() + "/api/pointTypes",
  REBOUND: getUrl() + "/api/rebounds",
  REBOUND_TYPE: getUrl() + "/api/reboundTypes",
  ROUND: getUrl() + "/api/rounds",
  ROLE: getUrl() + "/api/roles",
  ROLE_PROFILE: getUrl() + "/api/roles/profile",
  ROLE_PERMISSIONS: getUrl() + "/api/rolePermissions",
  SPORT: getUrl() + "/api/sports",
  STATISTICS: getUrl() + "/api/statistics",
  SUBSTITUTION: getUrl() + "/api/substitutions",
  PERMISSION: getUrl() + "/api/permissions",
  TEAM: getUrl() + "/api/teams",
  TURNOVER: getUrl() + "/api/turnovers",
  TURNOVER_TYPE: getUrl() + "/api/turnoverTypes",
  USER: getUrl() + "/api/users",
  USER_ROLE: getUrl() + "/api/userRoles",
  USER_PROFILE: getUrl() + "/api/users/profile",
  USER_CHANGE_PASSWORD: getUrl() + "/api/users/changePassword",
  REVIEW_PAYMENTS: getUrl() + "/api/reviewPayments",
  INCOME: getUrl() + "/api/income",
  EXPENSE: getUrl() + "/api/expense",
  EXERCISE: getUrl() + "/api/exercises",
  EXERCISE_TYPE: getUrl() + "/api/exerciseTypes",
  FILES: getUrl() + "/api/files",
  POSTS: `${getUrl()}/api/posts`,
  LEGAL_DOCUMENTS: `${getUrl()}/api/legal-documents`,
};

export default Endpoints;
