import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import { getString } from "../../../lang/Local";
import Endpoints from "../../../utils/Endpoints";
import { handleErrorMessage } from "../../../utils/Utils";
import useEffectOnce from "../../system/useEffectOnce";
import useStyles from "./ExerciseTypeStyle";

export default function ExercisesType() {
  const { token, showMessage } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const [exerciseType, setExerciseType] = useState({ name: "", description: "" });

  useEffectOnce(() => {
    if (id) {
      axios
        .get(Endpoints.EXERCISE_TYPE + "/" + id, headers(token))
        .then((response) => {
          setExerciseType(response.data);
        })
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  });

  const save = () => {
    axios
      .post(Endpoints.EXERCISE_TYPE, exerciseType, headers(token))
      .then(routeToMain)
      .catch((e) => handleErrorMessage(showMessage, e));
  };

  const routeToMain = () => history.push("/exercisesType");
  const handleChange = (e) => setExerciseType({ ...exerciseType, [e.target.name]: e.target.value });
  return (
    <Paper className={classes.section}>
      <ValidatorForm onSubmit={save}>
        <Grid container className={classes.main} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{id ? "Izmeni tip vežbe" : "Kreiraj tip vežbe"}</Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField name="name" label="Naziv" value={exerciseType.name} onChange={handleChange} variant="outlined" margin="normal" required fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField name="description" label="Opis" value={exerciseType.description} onChange={handleChange} variant="outlined" margin="normal" required fullWidth />
            </Grid>
          </Grid>

          <Grid className={classes.buttons} item xs={12}>
            <Button variant="contained" onClick={routeToMain} className={classes.button}>
              {getString("common.cancel")}
            </Button>
            <Button variant="contained" color="primary" type="onSubmit" className={classes.button}>
              {getString("common.save")}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Paper>
  );
}
