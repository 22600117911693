import { createContext, useContext } from "react";

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const headers = (token, params) => ({
  headers: {
    "Content-Type": "application/json;",
    Authorization: "Bearer " + token,
  },
  params: params ?? {},
});

export const headersDelete = (token, data) => {
  return {
    headers: {
      "Content-Type": "application/json;",
      Authorization: "Bearer " + token,
    },
    data: data ?? {},
  };
};
