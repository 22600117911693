import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import InfoIcon from "@material-ui/icons/Info";
import PeopleIcon from "@material-ui/icons/People";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import React, { useState } from "react";
import { useParams } from "react-router";
import MemberEvent from "./MemberEvent";
import MemberFees from "./MemberFees";
import MemberInfo from "./MemberInfo";
import useStyles from "./MemberStyle";

const TAB_INFO = 0;
const TAB_EVENT = 1;
const TAB_FEES = 2;

export default function Member() {
  const classes = useStyles();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState(TAB_INFO);

  return (
    <div className={classes.root}>
      <Paper>
        <AppBar position="static" color="default">
          <Tabs value={selectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} variant="fullWidth" indicatorColor="primary" textColor="primary" aria-label="icon tabs example">
            <Tab label="Info" icon={<InfoIcon />} />
            <Tab label="Događaji" icon={<SportsEsportsIcon />} disabled={!id} />
            <Tab label="Članarina" icon={<PeopleIcon />} disabled={!id} />
          </Tabs>
        </AppBar>
      </Paper>
      {selectedTab === TAB_INFO && <MemberInfo />}
      {selectedTab === TAB_EVENT && <MemberEvent />}
      {selectedTab === TAB_FEES && <MemberFees />}
    </div>
  );
}
