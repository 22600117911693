export const EventTypes = {
  TRAINING: "TRAINING",
  GAME: "GAME",
};

export const eventTypeLabel = (string) => {
  switch (string) {
    case EventTypes.TRAINING:
      return "Trening";
    case EventTypes.GAME:
      return "Utakmica";
    default:
      return "";
  }
};
