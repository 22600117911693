import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { headers, useAuth } from "../../../../context/auth";
import Endpoints from "../../../../utils/Endpoints";
import MessageType from "../../../../utils/MessageType";
import ReactTable from "../../../system/ReactTable";

const columns = [
  { title: "Ime i prezime", field: "fee.member.fullName" },
  { title: "Mesec", field: "fee.month" },
  { title: "Godina", field: "fee.year" },
  { title: "Tim", field: "fee.team.name" },
  { title: "Iznos", field: "fee.amount" },
];

export default function ReviewPayment({ reviewPayment, close, callbackAction }) {
  const { token, showMessage } = useAuth();

  const acceptReviewPayment = () => {
    axios
      .post(`${Endpoints.REVIEW_PAYMENTS}/accept/${reviewPayment.id}`, {}, headers(token))
      .then((response) => {
        showMessage("Uspešno prihvaćeno.", MessageType.SUCCESS);
        if (callbackAction) {
          callbackAction();
        }
      })
      .catch((e) => {
        showMessage("Greška prilikom prihvatanja.", MessageType.ERROR);
      });
  };

  const declineReviewPayment = () => {
    axios
      .post(`${Endpoints.REVIEW_PAYMENTS}/decline/${reviewPayment.id}`, {}, headers(token))
      .then((response) => {
        showMessage("Uspešno odbijeno.", MessageType.SUCCESS);
        if (callbackAction) {
          callbackAction();
        }
      })
      .catch((e) => {
        showMessage("Greška prilikom odbijanja.", MessageType.ERROR);
      });
  };

  return (
    <Dialog open={!!reviewPayment} onClose={close} aria-labelledby="form-dialog-title" maxWidth="lg">
      <DialogTitle id="form-dialog-title">Pregled članarine ({reviewPayment?.total}) din</DialogTitle>
      <DialogContent>
        <ReactTable
          columns={columns}
          data={reviewPayment?.paymentItemList ?? []}
          opt={{
            title: "",
            item: "reviewPayments",
            hideActions: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={close}>
          Odustani
        </Button>
        <Button variant="contained" color="primary" onClick={declineReviewPayment}>
          Poništi
        </Button>
        <Button variant="contained" color="primary" onClick={acceptReviewPayment}>
          Prihvati
        </Button>
      </DialogActions>
    </Dialog>
  );
}
