import { Document, Font, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  title: {
    borderTop: "none",
    fontSize: 16,
    fontFamily: "Roboto",
    marginBottom: 10,
    textAlign: "center",
  },
  table: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    fontFamily: "Roboto",
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 8,
    border: "1px solid black",
    padding: 5,
    width: "100%",
  },
  header: {
    borderTop: "none",
    fontSize: 8,
    fontFamily: "Roboto",
  },
  bold: {
    fontWeight: "bold",
  },
  // So Declarative and unDRY 👌
  row1: {
    width: "7%",
  },
  row2: {
    width: "43%",
  },
  row3: {
    width: "25%",
    fontSize: 8,
  },
  row4: {
    width: "30%",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
});

export default function MemberBookPdf({ users }) {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>Spisak članova</Text>

        <View style={styles.table}>
          <View style={[styles.row, styles.bold, styles.header]}>
            <Text style={styles.row1}>R. Br.</Text>
            <Text style={styles.row2}>Ime i prezime člana</Text>
            <Text style={styles.row3}>Broj evidencionog lista</Text>
            <Text style={styles.row4}>Upravljačka prava, član organa i funkcija</Text>
          </View>
          {users?.map((user, i) => (
            <View key={i} style={styles.row} wrap={false}>
              <Text style={styles.row1}>{++i}.</Text>
              <Text style={styles.row2}>{user.fullName}</Text>
              <Text style={styles.row3}>{user.clubNumberId}</Text>
              <Text style={styles.row4}>Redovni član</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
