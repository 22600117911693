import { Button, CssBaseline, Grid, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { MUIEditorState, toolbarControlTypes } from "react-mui-draft-wysiwyg";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import { getString } from "../../../lang/Local";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { handleErrorMessage, richTextStateFromHTML, richTextStateToHtml, richTextValue } from "../../../utils/Utils";
import { Panel } from "../../panel";
import RichText from "../../system/rich-text/RichText";
import useEffectOnce from "../../system/useEffectOnce";
import useStyles from "./PostStyle";
const POST_TYPE_TEXT = "TEXT";
const POST_TYPE_YOUTUBE = "YOUTUBE";

const POST_VIEW_TYPE_ALL = "ALL";
const POST_VIEW_TYPE_TEAM = "TEAM";

const postTypes = [
  { id: POST_TYPE_TEXT, name: "Tekst" },
  { id: POST_TYPE_YOUTUBE, name: "Youtube" },
];

const postViewTypes = [
  { id: POST_VIEW_TYPE_ALL, name: "Svi" },
  { id: POST_VIEW_TYPE_TEAM, name: "Tim" },
];

export default function Post() {
  const { token, showMessage } = useAuth();
  const history = useHistory();
  const classes = useStyles();

  const { id } = useParams();
  const textRef = useRef();
  const [teams, setTeams] = useState([]);
  const [post, setPost] = useState({
    title: "",
    subtitle: "",
    postType: "",
    postViewType: "",
    text: MUIEditorState.createEmpty(),
    link: "",
  });

  const validation = () => {
    const { title, postType, postViewType, teamIds } = post;

    if (!title) {
      showMessage("Naslov je obavezno polje.", MessageType.ERROR);
      return false;
    }
    if (!postType) {
      showMessage("Tip je obavezno polje.", MessageType.ERROR);
      return false;
    }
    if (postViewType.id === POST_VIEW_TYPE_TEAM && (!teamIds || teamIds.length < 1)) {
      showMessage("Timovi su obavezno polje.", MessageType.ERROR);
      return false;
    }

    if (!postViewType) {
      showMessage("Vidiljivost je obavezno polje.", MessageType.ERROR);
      return false;
    }

    if (!richTextStateToHtml(richTextValue(textRef))) {
      showMessage("Tekst je obavezno polje.", MessageType.ERROR);
      return false;
    }

    return true;
  };

  const fetchData = () => {
    axios
      .get(Endpoints.TEAM, headers(token))
      .then((result) => setTeams(result.data))
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });

    if (id) {
      axios
        .get(`${Endpoints.POSTS}/${id}`, headers(token))
        .then((result) => {
          setPost({
            ...result.data,
            postType: postTypes.find((pt) => pt.id === result.data.postType),
            postViewType: postViewTypes.find((pt) => pt.id === result.data.postViewType),
            teamIds: result.data.postTeamList?.map((pt) => pt.team),
          });
          textRef.current.set(richTextStateFromHTML(result.data.text));
        })
        .catch((e) => handleErrorMessage(showMessage, e));
    }
  };
  useEffectOnce(fetchData);

  const save = () => {
    if (validation()) {
      const postSave = {
        ...post,
        postType: post.postType?.id,
        postViewType: post.postViewType?.id,
        teamIds: post.teamIds?.map((t) => t.id),
        text: richTextStateToHtml(richTextValue(textRef)),
      };

      axios
        .post(Endpoints.POSTS, postSave, headers(token))
        .then((res) => {
          showMessage("Uspešno snimljeno", MessageType.SUCCESS);
          history.push("/posts");
        })
        .catch((e) => handleErrorMessage(e));
    }
  };

  const handleChange = (e) => setPost({ ...post, [e.target.name]: e.target.value });

  const muiControls = [
    toolbarControlTypes.undo,
    toolbarControlTypes.redo,
    toolbarControlTypes.divider,
    toolbarControlTypes.bold,
    toolbarControlTypes.italic,
    toolbarControlTypes.underline,
    toolbarControlTypes.strikethrough,
    toolbarControlTypes.fontColor,
    toolbarControlTypes.fontBackgroundColor,
    toolbarControlTypes.divider,
    toolbarControlTypes.linkAdd,
    toolbarControlTypes.linkRemove,
    // toolbarControlTypes.image,
    toolbarControlTypes.divider,
    toolbarControlTypes.blockType,
    toolbarControlTypes.fontSize,
    toolbarControlTypes.fontFamily,
    toolbarControlTypes.textAlign,
    toolbarControlTypes.divider,
    toolbarControlTypes.unorderedList,
    toolbarControlTypes.orderedList,
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Panel title={id ? "Obaveštenje" : "Novo obaveštenje"} />
      <ValidatorForm>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid spacing={2} container>
              <Grid item xs={12}>
                <TextField variant="outlined" margin="normal" id="title" name="title" label="Naslov" value={post.title} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField variant="outlined" margin="normal" id="subtitle" name="subtitle" label="Podnaslov" value={post.subtitle} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  options={postViewTypes}
                  getOptionLabel={(option) => option.name}
                  value={post?.postViewType}
                  onChange={(event, value) =>
                    setPost({
                      ...post,
                      postViewType: value ?? {},
                    })
                  }
                  renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Vidljivost" fullWidth />}
                />
              </Grid>
              <Grid item xs={6}>
                {post?.postViewType.id === POST_VIEW_TYPE_TEAM && (
                  <Autocomplete
                    multiple
                    options={teams}
                    getOptionLabel={(option) => option.name}
                    value={post?.teamIds}
                    onChange={(event, value) =>
                      setPost({
                        ...post,
                        teamIds: value ?? {},
                      })
                    }
                    renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Timovi" fullWidth />}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  options={postTypes}
                  getOptionLabel={(option) => option.name}
                  value={post.postType}
                  onChange={(event, value) =>
                    setPost({
                      ...post,
                      postType: value ?? {},
                    })
                  }
                  renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Tip obaveštenja" fullWidth />}
                />
              </Grid>
              <Grid item xs={3}>
                {post?.postType.id === POST_TYPE_YOUTUBE && (
                  <TextField variant="outlined" margin="normal" id="link" name="link" label="Link" value={post.link} onChange={handleChange} required fullWidth />
                )}
              </Grid>
              <Grid item xs={12}>
                <RichText muiEditorRef={textRef} />
              </Grid>
            </Grid>

            <Grid className={classes.padding_2} container spacing={1} justify="flex-end">
              <Grid item>
                <Button variant="contained" onClick={(e) => history.push("/posts")}>
                  {getString("common.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={save}>
                  {getString("common.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </ValidatorForm>
    </div>
  );
}
