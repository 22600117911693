import axios from "axios";
import { useEffect, useState } from "react";
import { headers, useAuth } from "../../context/auth";
import Endpoints from "../../utils/Endpoints";

const useSidemenu = () => {
  const { user, token } = useAuth();
  const [club, setClub] = useState();

  useEffect(() => {
    axios
      .get(Endpoints.CLUB + "/" + user?.club?.id, headers(token))
      .then((response) => {
        setClub(response.data);
      })
      .catch((e) => {});
  }, []);

  return {
    isFinanceActive: club?.isFinanceActive,
  };
};

export default useSidemenu;
