import React from "react";
import { CssBaseline, Paper, Grid } from "@material-ui/core";
import useStyles from "../user/UserStyle";
import UserInfo from "./UserInfo";
import UserGroups from "./UserGroups";

export default function User() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container>
        <Grid item xs={8}>
          <Paper className={classes.section}>
            <UserInfo />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.section}>
            <UserGroups />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
