import axios from "axios";
import Endpoints from "../utils/Endpoints";
import { axiosConfig } from "../utils/Utils";

export function UserService() {
  const axiosGetUser = (token) => axios.get(Endpoints.USER_PROFILE, axiosConfig(token));

  return {
    axiosGetUser,
  };
}
