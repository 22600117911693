import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3),
  },
  main: {
    padding: theme.spacing(2),
  },

  buttons: { textAlign: "right" },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
