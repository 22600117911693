import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function Copyright() {
    return (
        <div>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://evico.app">
                    EVICO
                </Link>
                {' '}{new Date().getFullYear()}{'.'}
            </Typography>
        </div>
    );
}