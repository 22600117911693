import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import logo_transparent from "../img/logo_transparent.png";
import Endpoints from "../utils/Endpoints";
import { handleErrorMessage } from "../utils/Utils";
import { getString } from "./../lang/Local";
import useStyles from "./LoginStyle";
import Copyright from "./system/Copyright";

function Login(props) {
  const { showMessage } = useAuth();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isUserEmpty, setIsUserEmpty] = useState(false);
  const [isPassEmpty, setIsPassEmpty] = useState(false);
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const { updateAuthData } = useAuth();
  const classes = useStyles();
  const referer = props.location.state ? props.location.state.referer : "/admin";

  const postLogin = (e) => {
    e.preventDefault();
    if (!userName || !password) {
      setIsUserEmpty(!userName);
      setIsPassEmpty(!password);
      return false;
    } else {
      setIsUserEmpty(false);
      setIsPassEmpty(false);
    }

    const postData = {
      username: userName,
      password: password,
      appUsername: "evico",
      appPassword: "evidationcoach",
    };
    axios
      .post(Endpoints.AUTHENTICATE, postData)
      .then((result) => {
        updateAuthData(result.data);
        setLoggedIn(true);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  if (isLoggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo_transparent} className={classes.logo} />
        <form className={classes.form} onSubmit={postLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label={getString("login.username")}
            name="username"
            autoComplete="username"
            autoFocus
            error={isUserEmpty}
            helperText={isUserEmpty && getString("login.error_input")}
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={getString("login.password")}
            type="password"
            id="password"
            autoComplete="current-password"
            error={isPassEmpty}
            helperText={!!isPassEmpty && getString("login.error_input")}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label={getString("login.remember_me")} /> */}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {getString("login.sign_in")}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default Login;
