const parseOptions = (array) => {
  return array.map((obj) => {
    return parseOption(obj);
  });
};

const parseOption = (obj) => {
  return obj ? { id: obj.id, title: obj.name } : { id: "", title: "" };
};

export { parseOptions, parseOption };
