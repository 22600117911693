import MomentUtils from "@date-io/moment";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { headers, useAuth } from "../../../context/auth";
import Endpoints from "../../../utils/Endpoints";
import MessageType from "../../../utils/MessageType";
import { DATE_FORMAT3, handleErrorMessage } from "../../../utils/Utils";
import UploadImage from "../../system/upload-image/UploadImage";
import useEffectOnce from "../../system/useEffectOnce";
import { getString } from "./../../../lang/Local";
import useStyles from "./UserStyle";

export default function UserInfo(props) {
  const { token, showMessage } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    birthDate: null,
    contactPhone: "",
    email: "",
    username: "",
    adressNumber: "",
    mailNumber: "",
    city: "",
    licenceNumber: "",
    admin: false,
    password: "",
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    newPassword: "",
    confirmPassword: "",
    role: "ADMIN",
  });

  const [showDialogChangePassword, setShowDialogChangePassword] = useState(false);
  const changePassword = () => {
    if (!user.newPassword) {
      showMessage("Polje nova lozinka je obavezno.", MessageType.ERROR);
      return;
    } else if (!user.confirmPassword) {
      showMessage("Polje potvrdna lozinka je obavezno.", MessageType.ERROR);
      return;
    } else if (user.confirmPassword !== user.newPassword) {
      showMessage("Potvrdna lozinka mora biti ista kao i nova lozinka.", MessageType.ERROR);
      return;
    }
    const data = {
      newPassword: user.newPassword,
      confirmPassword: user.confirmPassword,
    };
    axios
      .post(`${Endpoints.USER}/${id}/changePassword`, data, headers(token))
      .then((result) => {
        showMessage("Uspešno izmenjena lozinka.", MessageType.SUCCESS);
        setShowDialogChangePassword(false);
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  const openDialogResetPassword = () => {
    setShowDialogChangePassword(true);
    setUser({ ...user, newPassword: "", confirmPassword: "" });
  };

  const closeDialogChangePasswrod = () => {
    setShowDialogChangePassword(false);
  };

  useEffectOnce(() => {
    if (id) {
      axios
        .get(Endpoints.USER + "/" + id, headers(token))
        .then((response) => {
          setUser(response.data);
        })
        .catch((e) => {
          handleErrorMessage(showMessage, e);
        });
    }
  });

  const save = (e) => {
    const newUser = {
      ...user,
      birthDate: user.birthDate ? moment(user.birthDate).format(DATE_FORMAT3) : null,
      role: "ADMIN",
    };
    axios
      .post(Endpoints.USER, newUser, headers(token))
      .then((result) => {
        history.push("/users");
      })
      .catch((e) => {
        handleErrorMessage(showMessage, e);
      });
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <ValidatorForm>
        <Grid container className={classes.main} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">{id ? "Trener" : "Novi trener"}</Typography>
          </Grid>
          <UploadImage object={user} setObject={setUser} />
          <Grid container xs={10}>
            <Grid spacing={2} container>
              <Grid item xs={4}>
                <TextField variant="outlined" margin="normal" id="firstName" name="firstName" label="Ime" value={user.firstName} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={4}>
                <TextField variant="outlined" margin="normal" id="lastName" name="lastName" label="Prezime" value={user.lastName} onChange={handleChange} required fullWidth />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    variant="inline"
                    inputVariant="outlined"
                    openTo="year"
                    margin="normal"
                    label="Datum rođenja"
                    format={"DD.MM.YYYY."}
                    value={user.birthDate}
                    InputAdornmentProps={{ position: "start", variant: "standard" }}
                    onChange={(value) => {
                      setUser({ ...user, birthDate: value._d });
                    }}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid spacing={2} container>
              <Grid item xs={4}>
                <TextField fullWidth variant="outlined" margin="normal" id="contactPhone" name="contactPhone" label="Broj telefona" value={user.contactPhone} onChange={handleChange} />
              </Grid>
              <Grid item xs={4}>
                <TextValidator
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  id="email"
                  name="email"
                  label="Email"
                  value={user.email}
                  onChange={handleChange}
                  validators={["isEmail"]}
                  errorMessages={["Email je neispravan"]}
                />
              </Grid>

              <Grid item xs={4}>
                <TextField fullWidth variant="outlined" margin="normal" id="adressNumber" name="adressNumber" label="Adresa" value={user.adressNumber} onChange={handleChange} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth variant="outlined" margin="normal" id="mailNumber" name="mailNumber" label="Poštanski broj" value={user.mailNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth variant="outlined" margin="normal" id="city" name="city" label="Grad" value={user.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth variant="outlined" margin="normal" id="licenceNumber" name="licenceNumber" label="Broj licence" value={user.licenceNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth variant="outlined" margin="normal" id="username" name="username" label="Username" value={user.username} onChange={handleChange} required />
          </Grid>
          {!id && (
            <Grid item xs={4}>
              <TextField fullWidth variant="outlined" margin="normal" id="password" name="password" label="Password" value={user.password} onChange={handleChange} required />
            </Grid>
          )}
          {user.admin && (
            <Grid item xs={4}>
              <FormControl margin="normal" variant="outlined" fullWidth>
                <InputLabel>Lozinka</InputLabel>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={user.showPassword ? "text" : "password"}
                  onChange={handleChange}
                  value={user.password}
                  endAdornment={
                    <InputAdornment>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setUser({ ...user, showPassword: !user.showPassword });
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                        edge="end"
                      >
                        {user.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          )}

          <Grid className={classes.buttons} item xs={12}>
            <Button variant="contained" onClick={(e) => history.push("/users")} className={classes.button}>
              {getString("common.cancel")}
            </Button>
            {id && (
              <Button variant="outlined" color="primary" onClick={openDialogResetPassword} className={classes.button}>
                Promena lozinke
              </Button>
            )}
            <Button variant="contained" color="primary" onClick={save} className={classes.button}>
              {getString("common.save")}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
      <Dialog open={showDialogChangePassword} onClose={closeDialogChangePasswrod} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Promena lozinke</DialogTitle>
        <DialogContent>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel>Nova lozinka</InputLabel>
            <OutlinedInput
              id="newPassword"
              name="newPassword"
              type={user.showNewPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setUser({ ...user, showNewPassword: !user.showNewPassword });
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {user.showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel>Potvrda lozinke</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              name="confirmPassword"
              type={user.showConfirmPassword ? "text" : "password"}
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment>
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setUser({ ...user, showConfirmPassword: !user.showConfirmPassword });
                    }}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    edge="end"
                  >
                    {user.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={110}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogChangePasswrod}>Odustani</Button>
          <Button onClick={changePassword} color="primary" variant="contained">
            Snimi
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
